import React, { Component } from "react";
import axios from "axios";
import URLs from "../../utils/apis";
import { message, Spin, DatePicker } from "antd";
import moment from "moment";

export default class Step2PersonalDetiails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      grooms_name: "",
      marital_status: "",
      dob: null,
      religious_status: "",
      born_or_reverted: "",
      mathhab_followed: "",
      about_self: "",
      own_age: 0,
      loading: false,
      got_user_id: false,
    };
  }
  componentDidMount() {
    // console.log("personal on mount this.props.user_id", this.props.user_id);
    // if (this.state.got_user_id == false) {
    if (localStorage.getItem("registered_user_id")) {
      this.setState(
        {
          got_user_id: true,
        },
        () => this.get_user_details()
      );
    }
    // }
  }
  componentDidCatch() {
    // console.log("personal updated this.props.user_id 2", this.props.user_id);
    // if (this.state.got_user_id == false) {
    if (localStorage.getItem("registered_user_id")) {
      this.setState(
        {
          got_user_id: true,
        },
        () => this.get_user_details()
      );
    }
    // }
  }
  get_user_details = () => {
    let params = {
      user_id: localStorage.getItem("registered_user_id"),
    };
    axios
      .post(URLs.get_register_details, params)
      .then((response) => {
        if (response.data.status == "1") {
          if (response.data.data.length > 0) {
            // console.log("response.data.data", response.data.data);
            this.setState({
              marital_status: response.data.data[0].marital_status,
              dob: response.data.data[0].dob
                ? moment(response.data.data[0].dob)
                : null,
              own_age: response.data.data[0].own_age,
              born_or_reverted: response.data.data[0].born_or_reverted,
              mathhab_followed: response.data.data[0].mathhab_followed,
              about_self: response.data.data[0].about_self,
            });
          }
          return;
        } else {
        }
      })
      .catch((error) => {});
  };
  save = () => {
    if (!this.state.dob) {
      message.warning("Date of birth is required.");
      return;
    }
    if (this.state.own_age < 18) {
      message.warning(
        "Age should be at least 18 Years. Please select valid Date of birth"
      );
      return;
    }
    if (!this.state.marital_status) {
      message.warning("Marital Status is required.");
      return;
    }
    let params = {
      marital_status: this.state.marital_status
        ? this.state.marital_status
        : "",
      dob: this.state.dob ? moment(this.state.dob).format("YYYY-MM-DD") : "",
      own_age: this.state.own_age,
      born_or_reverted: this.state.born_or_reverted
        ? this.state.born_or_reverted
        : "",
      mathhab_followed: this.state.mathhab_followed
        ? this.state.mathhab_followed
        : "",
      about_self: this.state.about_self ? this.state.about_self : "",
      // user_id: this.props.user_id,
      user_id: localStorage.getItem("registered_user_id"),
      step: "2",
    };
    this.setState({ loading: true });
    axios
      .post(URLs.register, params)
      .then((response) => {
        if (response.data.status == "1") {
          message.success(response.data.message);
          this.setState(
            {
              loading: false,
            },
            () => this.props.change(3)
          );
          return;
        } else {
          this.setState({
            loading: false,
          });
          message.warning(response.data.message);
          return;
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
        message.warning("Please try again.");
      });
  };
  calculateAge = (birthDate) => {
    var currentDate = new Date();
    var birthDate = new Date(birthDate);

    var age = currentDate.getFullYear() - birthDate.getFullYear();

    // Adjust age if the current date is before the birthdate in the same year
    if (
      currentDate.getMonth() < birthDate.getMonth() ||
      (currentDate.getMonth() === birthDate.getMonth() &&
        currentDate.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    this.setState({
      own_age: age,
    });
    console.log("age", age);
    return age;
  };
  render() {
    return (
      <div className="container px-0 registration-page step2">
        <Spin spinning={this.state.loading}>
          <div className="row">
            <div className="col-lg-12">
              <h2>
                <b>Personal Details</b>
              </h2>
              <h6>Profile with more information gets better response</h6> <br />
              <div className="form-group">
                <label
                  for="dob"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <span>
                    Date of Birth <span className="text-danger">*</span>
                  </span>
                  <span>Age: {this.state.own_age} Years</span>
                </label>
                <DatePicker
                  value={this.state.dob}
                  onChange={(date, dateString) => {
                    console.log(date, dateString);
                    this.setState(
                      {
                        dob: date,
                      },
                      () => this.calculateAge(date)
                    );
                  }}
                  style={{
                    width: "100%",
                  }}
                  className="date-picker-input-box"
                />
                {/* <input
                  type="date"
                  value={this.state.dob}
                  onChange={(e) => {
                    this.setState(
                      {
                        dob: e.target.value,
                      },
                      () => this.calculateAge(e.target.value)
                    );
                  }}
                  id="dob"
                  name="dob"
                /> */}
                {this.state.own_age < 18 && this.state.own_age != "0" && (
                  <span className="text-danger">Invalid Date of Birth</span>
                )}
              </div>
              <div className="form-group">
                <label for="Marital Status">
                  Marital Status <span className="text-danger">*</span>
                </label>
                <select
                  name="marital_status"
                  value={this.state.marital_status}
                  onChange={(e) => {
                    this.setState({
                      marital_status: e.target.value,
                    });
                  }}
                  id="marital_status"
                >
                  <option value="">Select</option>
                  <option value="Single">Single</option>
                  <option value="Married">Married</option>
                  <option value="Widowed">Widowed</option>
                  <option value="Separated">Separated</option>
                  <option value="Divorced">Divorced</option>
                </select>
              </div>
              <div className="form-group">
                <label for="math'hab followed">Math'hab Followed</label>
                <select
                  value={this.state.mathhab_followed}
                  onChange={(e) => {
                    this.setState({
                      mathhab_followed: e.target.value,
                    });
                  }}
                  name="math'hab followed"
                  id="math'hab followed"
                >
                  <option value="">Select</option>
                  <option value="Hanafi">Hanafi</option>
                  <option value="Hanbali">Hanbali</option>
                  <option value="Maliki">Maliki</option>
                  <option value="Shafi">Shafi</option>
                  <option value="Shia">Shia</option>
                  <option value="Just Muslim">Just Muslim</option>
                  <option value="Other">Other</option>
                </select>
              </div>
              <div className="form-group">
                <label for="Gender">Born or reverted</label> <br />
                <div className="gender-option">
                  <label for="male">Born</label>
                  <input
                    type="radio"
                    onClick={() => {
                      this.setState({
                        born_or_reverted: "Born",
                      });
                    }}
                    checked={
                      this.state.born_or_reverted == "Born" ? true : false
                    }
                    id="male"
                    name="born_or_reverted"
                    value="Born"
                  />
                </div>
                <div className="gender-option">
                  <label for="female" className="gender-option">
                    Reverted
                  </label>
                  <input
                    type="radio"
                    onClick={() => {
                      this.setState({
                        born_or_reverted: "Reverted",
                      });
                    }}
                    checked={
                      this.state.born_or_reverted == "Reverted" ? true : false
                    }
                    id="female"
                    name="born_or_reverted"
                    value="Reverted"
                  />
                </div>
                <center>
                  <hr style={{ width: "100%" }} />
                </center>
                <div className="form-group mt-2">
                  <label for="country">About Self</label>
                  <textarea
                    id="txtid"
                    name="txtname"
                    rows="3"
                    cols="50"
                    value={this.state.about_self}
                    onChange={(e) => {
                      this.setState({
                        about_self: e.target.value,
                      });
                    }}
                    maxlength="200"
                    className="form-control"
                    style={{ border: "1px solid #bdb5b5" }}
                  ></textarea>
                </div>
              </div>
              <button
                className="submit-button"
                type="button"
                onClick={() => this.save()}
              >
                Continue
              </button>
              <button
                className="submit-button"
                type="button"
                onClick={() => this.props.change(1)}
              >
                Back
              </button>
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}
