import React, { Component } from "react";
import { Modal, message } from "antd";
import axios from "axios";
import URLs from "../utils/apis";
export default class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalStatus: false,
      profile_image: "",
      username: "",
    };
  }
  componentDidMount() {
    if (!localStorage.getItem("r_id")) {
      localStorage.removeItem("r_id");
      localStorage.removeItem("r_name");
      localStorage.removeItem("r_gender");
      window.location.href = "/login";
    }
    this.get_profile_details();
  }
  get_profile_details = () => {
    let user_id = localStorage.getItem("r_id");
    let params = {
      id: user_id,
    };
    axios
      .post(URLs.get_profile_details, params)
      .then((response) => {
        if (response.data.status == "1") {
          if (response.data.data.images.length > 0) {
            let url = "";
            if (response.data.data.images[0].image) {
              url = URLs.image_base_url + response.data.data.images[0].image;
            }
            console.log("profile url", url);
            this.setState({
              profile_image:
                response.data.data.images[0].image != "" ||
                response.data.data.images[0].image != null
                  ? url
                  : "",
            });
          }
          this.setState({
            username: response.data.data.user_details.name,
          });
        }
      })
      .catch((error) => {
        console.error("Warning:", error);
      });
  };
  logout = () => {
    localStorage.removeItem("r_id");
    localStorage.removeItem("r_name");
    localStorage.removeItem("r_gender");
    message.success("Logged out sucessfully.");
    window.location.assign("/login");
  };
  showModal = () => {
    this.setState({
      modalStatus: true,
    });
  };
  closeModal = () => {
    this.setState({
      modalStatus: false,
    });
  };
  render() {
    return (
      <div className="list">
        <div className="basic-details">
          <img
            src={
              this.state.profile_image
                ? this.state.profile_image
                : "./assets/images/no-image-available.jpg"
            }
          />
          <div>
            <p>{this.state.username}</p>
            {/* <span>ID - 00000000</span> */}
          </div>
        </div>
        <ul>
          <li>
            <a href="/dashboard">Home</a>
          </li>
          <li>
            <a href="/profile">My Profile</a>
          </li>
          {/* <li>
            <a href="/recent-activities">Recent Activities</a>
          </li>
          <li>
            <a href="/privacy-policy">Privacy Settings</a>
          </li>
          <li>
            <a href="/upgrade">Pay Now</a>
          </li> */}
          <li>
            <a href="#" onClick={() => this.showModal()}>
              Sign out
            </a>
          </li>
        </ul>

        <div className="sidebar-footer">
          <div>
            <p>
              Developed By Affixtech.in <br />
              All right reserved to Rishtedari.in. Copyright 2024.
            </p>
          </div>
          <div className="social-icons">
            <div>
              <img src="./assets/images/icons/facebook.png" />
            </div>
            <div>
              <img src="./assets/images/icons/twitter.png" />
            </div>
            <div>
              <img src="./assets/images/icons/instagram.png" />
            </div>
          </div>
        </div>

        <Modal
          title="Logout"
          visible={this.state.modalStatus}
          onOk={() => this.logout()}
          onCancel={() => this.closeModal()}
        >
          <p style={{ fontSize: 18 }}>Are you sure you want to logout?</p>
        </Modal>
      </div>
    );
  }
}
