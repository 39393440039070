// let BASE_URL = "https://sapi.affixtech.in/api/";
// let BASE_URL2 = "https://sapi.affixtech.in/";
// let BASE_URL = "https://sapi.rishtedari.in/api/";
// let BASE_URL2 = "https://sapi.rishtedari.in/";

let BASE_URL = "https://rishterdari-api.rishtedari.in/api/";
let BASE_URL2 = "https://rishterdari-api.rishtedari.in/";

let URLs = {
  check_for_user: BASE_URL + "rishtedari/check_for_user_present",
  login: BASE_URL + "rishtedari/login",
  register: BASE_URL + "rishtedari/register_new_user",

  get_state_list: BASE_URL + "rishtedari/get_state_list",
  get_district_list: BASE_URL + "rishtedari/get_district_list",
  get_taluka_list: BASE_URL + "rishtedari/get_taluka_list",

  get_user_list: BASE_URL + "rishtedari/get_user_list",
  get_profile_details: BASE_URL + "rishtedari/get_profile_details",
  get_image_list: BASE_URL + "rishtedari/get_image_list",
  add_new_image: BASE_URL + "rishtedari/add_new_image",
  update_image: BASE_URL + "rishtedari/update_image",
  delete_image: BASE_URL + "rishtedari/delete_image",
  update_profile_details: BASE_URL + "rishtedari/update_profile_details",
  get_register_details: BASE_URL + "rishtedari/get_register_details",

  image_base_url: BASE_URL2,
};

export default URLs;
