import React, { Component } from "react";
import BottomNavigation from "./components/BottomNavigation";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default class UpgradePremium extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [
        {
          type: "Royal",
          price: "2080",
        },
        {
          type: "Luxury",
          price: "3080",
        },
        {
          type: "Ultimate",
          price: "4080",
        },
      ],
      currentSlide: 0, // Initially show the first slide
    };
  }
  goToSlide = (index) => {
    this.slider.slickGoTo(index); // Use slickGoTo method to navigate to the specified slide
    this.setState({ currentSlide: index }); // Update currentSlide in state
  };
  handleSlideChange = (currentSlide) => {
    this.setState({ currentSlide });
  };
  render() {
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      afterChange: this.handleSlideChange,
    };
    return (
      <>
        <div className="see-all-page premium-page">
          <div className="container px-0">
            <div
              className="row head-sec align-items-center"
              style={{ padding: "34px 9px 27px 23px" }}
            >
              <div className="col-12">
                <div className="left">
                  <a href="/dashboard">
                    <img
                      style={{ height: 18 }}
                      src="./assets/images/back-arrow.png"
                      alt="arrow"
                    />
                  </a>
                  <h3>Upgrade to Premium</h3>
                  <div></div>
                </div>
              </div>
            </div>
            <img
              src="./assets/images/upgrade-image.png"
              style={{
                height: 300,
                width: "100%",
                objectFit: "cover",
              }}
            />
            <div className="plan-tabs">
              <div className="plan-custom-tab-list">
                <div
                  className={`plan-tab-button ${
                    this.state.currentSlide == "0" && "active"
                  }`}
                  onClick={() => this.goToSlide(0)}
                >
                  Royal
                </div>
                <div
                  className={`plan-tab-button ${
                    this.state.currentSlide == "1" && "active"
                  }`}
                  onClick={() => this.goToSlide(1)}
                >
                  Luxury
                </div>
                <div
                  className={`plan-tab-button ${
                    this.state.currentSlide == "2" && "active"
                  }`}
                  onClick={() => this.goToSlide(2)}
                >
                  Ultimate
                </div>
              </div>
            </div>
          </div>
          <div className="plan-tab-content">
            <div className="inner-content">
              <Slider ref={(c) => (this.slider = c)} {...settings}>
                {this.state.list.map((item, index) => {
                  return (
                    <div className="single-item">
                      <h4>
                        <strong>{item.type}</strong> <span>2 Months</span>
                      </h4>
                      <p className="pricing">Rs. {item.price}</p>
                      <ul>
                        <li>
                          <box-icon name="check" color="#41c146"></box-icon>
                          &nbsp;&nbsp;
                          <span>Get 25 verified contact members</span>
                        </li>
                        <li>
                          <box-icon name="check" color="#41c146"></box-icon>
                          &nbsp;&nbsp;
                          <span>Instant chat & messages</span>
                        </li>
                        <li>
                          <box-icon name="check" color="#41c146"></box-icon>
                          &nbsp;&nbsp;
                          <span>Photo lock options</span>
                        </li>
                        <li>
                          <box-icon name="check" color="#41c146"></box-icon>
                          &nbsp;&nbsp;
                          <span>Get highlighted to matches</span>
                        </li>
                      </ul>
                      <span className="priority-text">
                        <s>Priority over free members</s>
                      </span>
                      <br />
                      <button className="button">Continue</button>
                    </div>
                  );
                })}
              </Slider>
            </div>
          </div>
          <br />
        </div>
        <BottomNavigation active="upgrade" />
      </>
    );
  }
}
