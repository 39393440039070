import React from "react";
import "boxicons";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Redirect,
} from "react-router-dom";
import SplashScreen from "./app/SplashScreen";
import SliderScreen from "./app/SliderScreen";
import Login from "./app/Login";
import Dashboard from "./app/Dashboard";
import Profile from "./app/Profile";
import SeeAll from "./app/SeeAll";
import Inbox from "./app/Inbox";
import UpgradePremium from "./app/UpgradePremium";
import UserDetails from "./app/UserDetails";
import Privacy from "./app/Privacy";
import RecentActivation from "./app/RecentActivation";
import EditProfileImages from "./app/EditProfileImages";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" exact element={<Login />} />
          <Route path="/slider-screen" exact element={<SliderScreen />} />
          <Route path="/login" exact element={<Login />} />
          <Route path="/dashboard" exact element={<Dashboard />} />
          <Route path="/user-details" exact element={<UserDetails />} />
          <Route path="/see-all-list" exact element={<SeeAll />} />
          <Route path="/messages" exact element={<Inbox />} />
          <Route path="/upgrade" exact element={<UpgradePremium />} />
          <Route path="/privacy-policy" exact element={<Privacy />} />
          <Route path="/profile" exact element={<Profile />} />
          <Route
            path="/edit-profile-images"
            exact
            element={<EditProfileImages />}
          />
          <Route
            path="/recent-activities"
            exact
            element={<RecentActivation />}
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
