import React, { Component } from "react";
import BottomNavigation from "./components/BottomNavigation";
import axios from "axios";
import URLs from "./utils/apis";
import { message, Table, Spin, Menu, Dropdown, Modal, Button } from "antd";
import Slider from "react-slick";
import OtherProfileUpdate from "./OtherProfileUpdate";

export default class EditProfileImages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      profile_image: [],
      image_list: [],
      selectedImage: "",
      editSelectedImage: "",
      addLoader: false,
      image_id: "",
      profile_image_url: "",
      deleteModalStatus: false,
      profile_details: {},
      type: "",
    };
  }
  componentDidMount() {
    const searchParams = new URLSearchParams(window.location.search);
    const type = searchParams.get("type");
    console.log("type:", type);
    this.setState(
      {
        type: type,
        user_id: localStorage.getItem("r_id"),
      },
      () => {
        if (type == "images") {
          this.get_profile_details();
        }
      }
    );
  }
  get_profile_details = () => {
    let params = {
      id: this.state.user_id,
    };
    this.setState({ loading: true });
    axios
      .post(URLs.get_image_list, params)
      .then((response) => {
        // console.log("response.data", response.data.data);
        if (response.data.status == "1") {
          let url = "";
          let imgarr = [];
          for (let item of response.data.data) {
            item.url = "";
            if (item.image) {
              url = URLs.image_base_url + item.image;
              item.image_url = url;
            }
            imgarr.push(item);
          }
          this.setState(
            {
              profile_images: imgarr.reverse(),
            },
            () => this.setState({ loading: false })
          );
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((error) => {
        // console.error("Warning:", error);
        this.setState({ loading: false });
      });
  };
  save = () => {
    if (!this.state.selectedImage) {
      message.warning("Profile image is required.");
      return;
    }
    let formdata = new FormData();
    formdata.append("profile_image", this.state.selectedImage);
    formdata.append("id", localStorage.getItem("r_id"));
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    this.setState({ addLoader: true });
    axios
      .post(URLs.add_new_image, formdata, config)
      .then((response) => {
        if (response.data.status == "1") {
          message.success(response.data.message);
          this.setState(
            {
              addLoader: false,
              addModalStatus: false,
            },
            () => {
              this.get_profile_details();
              setTimeout(() => {
                window.location.reload();
              }, 2000);
            }
          );
          return;
        } else {
          this.setState({
            addLoader: false,
          });
          message.warning(response.data.message);
          return;
        }
      })
      .catch((error) => {
        this.setState({ addLoader: false });
        // console.error("Warning:", error);
        message.warning("Please try again.");
      });
  };
  update_image = () => {
    if (!this.state.editSelectedImage) {
      message.warning("Profile image is required.");
      return;
    }
    let formdata = new FormData();
    formdata.append("profile_image", this.state.editSelectedImage);
    formdata.append("image_id", this.state.image_id);
    formdata.append("id", localStorage.getItem("r_id"));
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    this.setState({ addLoader: true });
    axios
      .post(URLs.update_image, formdata, config)
      .then((response) => {
        if (response.data.status == "1") {
          message.success(response.data.message);
          this.setState(
            {
              addLoader: false,
              editModalStatus: false,
              image_id: "",
            },
            () => {
              this.get_profile_details();
              setTimeout(() => {
                window.location.reload();
              }, 2000);
            }
          );
          return;
        } else {
          this.setState({
            addLoader: false,
          });
          message.warning(response.data.message);
          return;
        }
      })
      .catch((error) => {
        this.setState({ addLoader: false });
        // console.error("Warning:", error);
        message.warning("Please try again.");
      });
  };
  delete_image = () => {
    let formdata = new FormData();
    formdata.append("image_id", this.state.image_id);
    formdata.append("id", localStorage.getItem("r_id"));
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    this.setState({ addLoader: true });
    axios
      .post(URLs.delete_image, formdata, config)
      .then((response) => {
        if (response.data.status == "1") {
          message.success(response.data.message);
          this.setState(
            {
              addLoader: false,
              deleteModalStatus: false,
              image_id: "",
            },
            () => {
              this.get_profile_details();
              setTimeout(() => {
                window.location.reload();
              }, 2000);
            }
          );
          return;
        } else {
          this.setState({
            addLoader: false,
          });
          message.warning(response.data.message);
          return;
        }
      })
      .catch((error) => {
        this.setState({ addLoader: false });
        // console.error("Warning:", error);
        message.warning("Please try again.");
      });
  };
  openDeleteModal = (item) => {
    this.setState({
      image_id: item.image_id,
      deleteModalStatus: true,
    });
  };
  openModal = (item) => {
    let profile_image_url = URLs.image_base_url + item.image;
    this.setState({
      image_id: item.image_id,
      profile_image_url,
      editModalStatus: true,
    });
  };
  render() {
    const dataSource =
      this.state.profile_images &&
      this.state.profile_images.map((item, index) => {
        return {
          key: "1",
          image: item,
          action: item,
        };
      });
    const columns = [
      {
        title: "Image",
        dataIndex: "image",
        key: "image",
        render: (item) => {
          let image_url = item.image_url;
          return <img src={image_url} style={{ maxWidth: 70 }} />;
        },
      },
      {
        title: "Action",
        dataIndex: "action",
        key: "action",
        render: (item) => {
          const menu = (
            <Menu className="action-menu participant-action-menu">
              <Menu.Item key={`edit_details_${item.index}`}>
                <a
                  className="action-buttons"
                  href="#!"
                  onClick={() => this.openModal(item)}
                >
                  <i
                    className="fas fa-file-alt"
                    style={{ color: "#000", fontSize: 18 }}
                  ></i>
                  <span style={{ fontSize: 14, marginLeft: 10 }}>
                    Edit Details
                  </span>
                </a>
              </Menu.Item>
              <Menu.Item key={`edit_details_${item.index}`}>
                <a
                  className="action-buttons"
                  href="#!"
                  onClick={() => this.openDeleteModal(item)}
                >
                  <i
                    className="fas fa-file-alt"
                    style={{ color: "#000", fontSize: 18 }}
                  ></i>
                  <span style={{ fontSize: 14, marginLeft: 10 }}>Delete</span>
                </a>
              </Menu.Item>
            </Menu>
          );
          return (
            <div style={{ width: "100%" }}>
              <Dropdown overlay={menu}>
                <a
                  className="ant-dropdown-link action-link"
                  onClick={(e) => e.preventDefault()}
                >
                  <box-icon name="dots-vertical"></box-icon>
                </a>
              </Dropdown>
            </div>
          );
        },
      },
    ];
    return (
      <div style={{ backgroundColor: "#dddddd75", minHeight: "100vh" }}>
        <div className="back-div fixed-head-section">
          <a href="/profile">
            <div className="back-button">
              <box-icon name="chevron-left" size={"42px"}></box-icon>
            </div>
          </a>
          <h4>Update Profile</h4>
        </div>
        <Spin spinning={this.state.loading}>
          <div className="profile-details-page top-space-for-fixed-bar">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  {" "}
                  {this.state.type == "images" && (
                    <>
                      <br />
                      <Button
                        type="primary"
                        onClick={() =>
                          this.setState({
                            addModalStatus: true,
                            selectedImage: "",
                          })
                        }
                        style={{ marginBottom: 15 }}
                      >
                        Add New Image
                      </Button>
                      <br />
                      <p className="text-danger" style={{ fontWeight: "700" }}>
                        You can upload maximum 3 profile images.
                      </p>
                      <Table
                        dataSource={dataSource}
                        pagination={false}
                        columns={columns}
                      />
                      <br />
                    </>
                  )}
                  {/* Other Profile updates  */}
                  {this.state.type && (
                    <OtherProfileUpdate type={this.state.type} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </Spin>
        <br />
        <br />
        <br />
        <br />
        <BottomNavigation active="profile" />

        <Modal
          title="Add New Image"
          visible={this.state.addModalStatus}
          okText={"Save"}
          cancelText={"Close"}
          onOk={() => this.save()}
          onCancel={() =>
            this.setState({ selectedImage: "", addModalStatus: false })
          }
        >
          <p className="pt-3">
            <Spin spinning={this.state.addLoader}>
              <div className="form-group">
                <label for="height">
                  Profile Image <span className="text-danger">*</span>
                </label>
                <input
                  type="file"
                  name="email"
                  size="30"
                  maxlength="50"
                  onChange={(e) => {
                    this.setState({
                      selectedImage: e.target.files[0],
                    });
                  }}
                />
              </div>
            </Spin>
          </p>
        </Modal>

        <Modal
          title="Update Image"
          visible={this.state.editModalStatus}
          okText={"Update"}
          cancelText={"Close"}
          onOk={() => this.update_image()}
          onCancel={() =>
            this.setState({ editSelectedImage: "", editModalStatus: false })
          }
        >
          <p className="pt-3">
            <Spin spinning={this.state.addLoader}>
              <div className="form-group">
                <label for="height">
                  Profile Image <span className="text-danger">*</span>
                </label>
                {this.state.profile_image_url && (
                  <>
                    <br />
                    <img
                      src={this.state.profile_image_url}
                      style={{
                        maxWidth: 70,
                        padding: 7,
                        border: "1px solid #ddd",
                      }}
                      className="mt-2 mb-2"
                    />
                  </>
                )}
                <input
                  type="file"
                  name="email"
                  size="30"
                  maxlength="50"
                  onChange={(e) => {
                    this.setState({
                      editSelectedImage: e.target.files[0],
                    });
                  }}
                />
              </div>
            </Spin>
          </p>
        </Modal>

        <Modal
          title="Delete Image"
          visible={this.state.deleteModalStatus}
          okText={"Delete"}
          cancelText={"Close"}
          onOk={() => this.delete_image()}
          onCancel={() =>
            this.setState({ image_id: "", deleteModalStatus: false })
          }
        >
          <p className="pt-3">Are you sure you want to delete image?</p>
        </Modal>
      </div>
    );
  }
}
