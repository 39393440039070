import React, { Component } from "react";
import BottomNavigation from "./components/BottomNavigation";
import axios from "axios";
import URLs from "./utils/apis";
import { message, Spin } from "antd";
import Slider from "react-slick";

export default class UserDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user_details: {},
      user_id: 0,
      profile_images: [],
      about_self: "",
      username: "",
      marital_status: "",
      loading: false,
      dob: "",
      mother_toungue: "",
      profile_created_by: "",
      height: "",
      highest_qualification: "",
      working_sector: "",
      speciality_challenged: "",
      religious_status: "",
      age_range: "",
      partner_marital_status: "",
      partner_religion: "",
      partner_diffently_abled: "",
      partner_language: "",
      partner_education: "",
      partner_occupation: "",
      partner_state: "",
      email: "",
      phone: "",
      own_age: "",
      address: "",
      employee_in: "",
    };
  }
  componentDidMount() {
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    const id = params.get("id");
    if (!id) {
      message.warning("Invalid request");
      setTimeout(() => {
        window.location.assign("/dashboard");
      }, 2000);
    }
    this.setState(
      {
        user_id: id,
      },
      () => this.get_profile_details()
    );
  }
  get_profile_details = () => {
    let params = {
      id: this.state.user_id,
    };
    this.setState({ loading: true });
    axios
      .post(URLs.get_profile_details, params)
      .then((response) => {
        console.log("response.data", response.data.data.user_details);
        if (response.data.status == "1") {
          let url = "";
          let imgarr = [];
          if (response.data.data.images.length > 0) {
            for (let item of response.data.data.images) {
              item.url = "";
              if (item.image) {
                item.status = true;
                url = URLs.image_base_url + item.image;
                item.image_url = url;
              }
              imgarr.push(item);
            }
          } else {
            let url = "./assets/images/no-image-available.jpg";
            let obj = {
              status: false,
              image_url: url,
            };
            imgarr.push(obj);
          }
          this.setState(
            {
              profile_images: imgarr,
              address: response.data.data.user_details.address
                ? response.data.data.user_details.address
                : "",
              marital_status: response.data.data.user_details.marital_status
                ? response.data.data.user_details.marital_status
                : "",
              username: response.data.data.user_details.name
                ? response.data.data.user_details.name
                : "",
              state_name: response.data.data.user_details.state_name
                ? response.data.data.user_details.state_name
                : "",
              district_name: response.data.data.user_details.district_name
                ? response.data.data.user_details.district_name
                : "",
              taluka_name: response.data.data.user_details.taluka_name
                ? response.data.data.user_details.taluka_name
                : "",
              dob: response.data.data.user_details.dob
                ? response.data.data.user_details.dob
                : "",
              mother_toungue: response.data.data.user_details.mother_toungue
                ? response.data.data.user_details.mother_toungue
                : "",
              profile_created_by: response.data.data.user_details
                .profile_created_by
                ? response.data.data.user_details.profile_created_by
                : "",
              height: response.data.data.user_details.height
                ? response.data.data.user_details.height
                : "",
              highest_qualification: response.data.data.user_details
                .highest_qualification
                ? response.data.data.user_details.highest_qualification
                : "",
              employee_in: response.data.data.user_details.employee_in
                ? response.data.data.user_details.employee_in
                : "",
              religious_status: response.data.data.user_details.religious_status
                ? response.data.data.user_details.religious_status
                : "",
              about_self: response.data.data.user_details.about_self
                ? response.data.data.user_details.about_self
                : "",
              email: response.data.data.user_details.email
                ? response.data.data.user_details.email
                : "",
              phone: response.data.data.user_details.phone
                ? response.data.data.user_details.phone
                : "",
              disability: response.data.data.user_details.disability
                ? response.data.data.user_details.disability
                : "",
              mathhab_followed: response.data.data.user_details.mathhab_followed
                ? response.data.data.user_details.mathhab_followed
                : "",
              own_age: response.data.data.user_details.own_age
                ? response.data.data.user_details.own_age
                : "",
              born_or_reverted: response.data.data.user_details.born_or_reverted
                ? response.data.data.user_details.born_or_reverted
                : "",
              age_range: response.data.data.partner_details.age
                ? response.data.data.partner_details.age
                : "",
              partner_marital_status: response.data.data.partner_details
                .pmarital_status
                ? response.data.data.partner_details.pmarital_status
                : "",
              partner_religion: "",
              partner_diffently_abled: "",
              partner_language: response.data.data.partner_details.planguages
                ? response.data.data.partner_details.planguages
                : "",
              partner_education: response.data.data.partner_details.peducation
                ? response.data.data.partner_details.peducation
                : "",
              partner_disability: response.data.data.partner_details.pdisability
                ? response.data.data.partner_details.pdisability
                : "",
              partner_districts: response.data.data.partner_details.p_districts,
              partner_height: response.data.data.partner_details.pheight
                ? response.data.data.partner_details.pheight
                : "",
              partner_occupation: "",
              partner_state: response.data.data.partner_details.state_name
                ? response.data.data.partner_details.state_name
                : "",
              pmathhab_followed: response.data.data.partner_details
                .pmathhab_followed
                ? response.data.data.partner_details.pmathhab_followed
                : "",
            },
            () => this.setState({ loading: false })
          );
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((error) => {
        console.error("Warning:", error);
        this.setState({ loading: false });
      });
  };
  render() {
    const settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      // afterChange: this.handleSlideChange,
    };
    return (
      <div style={{ backgroundColor: "#dddddd75" }}>
        <div className="back-div fixed-head-section">
          <a href="/dashboard">
            <div className="back-button">
              <box-icon name="chevron-left" size={"42px"}></box-icon>
            </div>
          </a>
          <h4>Profile</h4>
        </div>
        <Spin spinning={this.state.loading}>
          <div className="profile-details-page top-space-for-fixed-bar">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <Slider ref={(c) => (this.slider = c)} {...settings}>
                    {this.state.profile_images.map((item, index) => {
                      return (
                        <div className="profile-item" key={index}>
                          <div
                            className="inner"
                            style={{
                              background: `url("${item.image_url}")`,
                              // height: 380,
                              // backgroundSize: "cover",
                              // backgroundPosition: "center center",
                              // width: "100%",
                            }}
                          >
                            <div className="backdrop">
                              <div className="top-head">
                                <p
                                  className="name"
                                  style={{
                                    background:
                                      item.status == false
                                        ? "#3e3e3e"
                                        : "#a7a7a7c9",
                                  }}
                                >
                                  {this.state.username}
                                </p>
                              </div>
                              <div className="other-details">
                                <p
                                  className="age"
                                  style={{
                                    background:
                                      item.status == false
                                        ? "#3e3e3e"
                                        : "#a7a7a7c9",
                                  }}
                                >
                                  {this.state.own_age} Y,{" "}
                                  {this.state.marital_status}
                                </p>
                                <p
                                  className="address"
                                  style={{
                                    background:
                                      item.status == false
                                        ? "#3e3e3e"
                                        : "#a7a7a7c9",
                                  }}
                                >
                                  {this.state.state_name}
                                  {this.state.state_name
                                    ? ", " + this.state.district_name
                                    : this.state.district_name}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </Slider>
                  <div className="about-self" style={{ display: "flex" }}>
                    <span style={{ fontSize: 18, fontWeight: 800 }}>Note:</span>
                    <span
                      className="mb-0"
                      style={{ paddingLeft: 15, fontSize: 17 }}
                    >
                      Please contact us to get profile details in pdf format.
                    </span>
                  </div>
                  <div className="about-self">
                    <div className="details-header d-flex justify-content-between align-items-center">
                      <div className="d-flex align-items-center">
                        <box-icon
                          type="solid"
                          name="user"
                          size={"19px"}
                        ></box-icon>
                        &nbsp;&nbsp;
                        <h5 className="mb-0 pb-0">About Myself</h5>
                      </div>
                    </div>
                    <p>
                      {this.state.about_self
                        ? this.state.about_self
                        : "Not available."}
                    </p>
                  </div>
                  <div className="about-self">
                    <div className="details-header d-flex justify-content-between align-items-center">
                      <div className="d-flex align-items-center">
                        <box-icon
                          type="solid"
                          name="user"
                          size={"19px"}
                        ></box-icon>
                        &nbsp;&nbsp;
                        <h5 className="mb-0 pb-0">Basic Details</h5>
                      </div>
                    </div>
                    <div className="all-list">
                      <div className="row">
                        <div className="col-5">
                          <p className="param">Name</p>
                        </div>
                        <div className="col-7">
                          <p className="value">
                            : &nbsp;&nbsp;
                            {this.state.username ? this.state.username : "---"}
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-5">
                          <p className="param">Email</p>
                        </div>
                        <div className="col-7">
                          <p className="value">
                            : &nbsp;&nbsp;
                            {this.state.email ? this.state.email : "---"}
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-5">
                          <p className="param">Phone No</p>
                        </div>
                        <div className="col-7">
                          <p className="value">
                            : &nbsp;&nbsp;
                            {this.state.phone ? this.state.phone : "---"}
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-5">
                          <p className="param">Mother Tounge</p>
                        </div>
                        <div className="col-7">
                          <p className="value">
                            : &nbsp;&nbsp;
                            {this.state.mother_toungue
                              ? this.state.mother_toungue
                              : "---"}
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-5">
                          <p className="param">Profile Created By</p>
                        </div>
                        <div className="col-7">
                          <p className="value">
                            : &nbsp;&nbsp;
                            {this.state.profile_created_by
                              ? this.state.profile_created_by
                              : "---"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="about-self">
                    <div className="details-header d-flex justify-content-between align-items-center">
                      <div className="d-flex align-items-center">
                        <box-icon
                          type="solid"
                          name="user"
                          size={"19px"}
                        ></box-icon>
                        &nbsp;&nbsp;
                        <h5 className="mb-0 pb-0">Personal Details</h5>
                      </div>
                    </div>
                    <div className="all-list">
                      <div className="row">
                        <div className="col-6">
                          <p className="param">Marital Status</p>
                        </div>
                        <div className="col-6">
                          <p className="value">
                            : &nbsp;&nbsp;
                            {this.state.marital_status
                              ? this.state.marital_status
                              : "---"}
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <p className="param">Date of Birth</p>
                        </div>
                        <div className="col-6">
                          <p className="value">
                            : &nbsp;&nbsp;
                            {this.state.dob ? this.state.dob : "---"}
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <p className="param">Age</p>
                        </div>
                        <div className="col-6">
                          <p className="value">
                            : &nbsp;&nbsp;
                            {this.state.own_age
                              ? this.state.own_age + "Year"
                              : "---"}
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <p className="param">Math'hab Followed</p>
                        </div>
                        <div className="col-6">
                          <p className="value">
                            : &nbsp;&nbsp;
                            {this.state.mathhab_followed
                              ? this.state.mathhab_followed
                              : "---"}
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <p className="param">Born or reverted</p>
                        </div>
                        <div className="col-6">
                          <p className="value">
                            : &nbsp;&nbsp;
                            {this.state.born_or_reverted
                              ? this.state.born_or_reverted
                              : "---"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="about-self">
                    <div className="details-header d-flex justify-content-between align-items-center">
                      <div className="d-flex align-items-center">
                        <box-icon
                          type="solid"
                          name="user"
                          size={"19px"}
                        ></box-icon>
                        &nbsp;&nbsp;
                        <h5 className="mb-0 pb-0">Your Appearance </h5>
                      </div>
                    </div>
                    <div className="all-list">
                      <div className="row">
                        <div className="col-6">
                          <p className="param">Height</p>
                        </div>
                        <div className="col-6">
                          <p className="value">
                            : &nbsp;&nbsp;
                            {this.state.height ? this.state.height : "---"}
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <p className="param">Disability</p>
                        </div>
                        <div className="col-6">
                          <p className="value">
                            : &nbsp;&nbsp;
                            {this.state.disability
                              ? this.state.disability
                              : "---"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="about-self">
                    <div className="details-header d-flex justify-content-between align-items-center">
                      <div className="d-flex align-items-center">
                        <box-icon
                          type="solid"
                          name="user"
                          size={"19px"}
                        ></box-icon>
                        &nbsp;&nbsp;
                        <h5 className="mb-0 pb-0">Place you are from </h5>
                      </div>
                    </div>
                    <div className="all-list">
                      <div className="row">
                        <div className="col-6">
                          <p className="param">State Name</p>
                        </div>
                        <div className="col-6">
                          <p className="value">
                            : &nbsp;&nbsp;
                            {this.state.state_name
                              ? this.state.state_name
                              : "---"}
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <p className="param">District Name</p>
                        </div>
                        <div className="col-6">
                          <p className="value">
                            : &nbsp;&nbsp;
                            {this.state.district_name
                              ? this.state.district_name
                              : "---"}
                          </p>
                        </div>
                      </div>
                      {/*<div className="row">
                        <div className="col-6">
                          <p className="param">Taluka Name</p>
                        </div>
                        <div className="col-6">
                          <p className="value">
                            : &nbsp;&nbsp;
                            {this.state.taluka_name
                              ? this.state.taluka_name
                              : "---"}
                          </p>
                        </div>
                      </div>*/}
                      <div className="row">
                        <div className="col-6">
                          <p className="param">Address</p>
                        </div>
                        <div className="col-6">
                          <p className="value">
                            : &nbsp;&nbsp;
                            {this.state.address ? this.state.address : "---"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="about-self">
                    <div className="details-header d-flex justify-content-between align-items-center">
                      <div className="d-flex align-items-center">
                        <box-icon
                          type="solid"
                          name="user"
                          size={"19px"}
                        ></box-icon>
                        &nbsp;&nbsp;
                        <h5 className="mb-0 pb-0">About Qualification</h5>
                      </div>
                    </div>
                    <div className="all-list">
                      <div className="row">
                        <div className="col-6">
                          <p className="param">Highest Qualification</p>
                        </div>
                        <div className="col-6">
                          <p className="value">
                            : &nbsp;&nbsp;
                            {this.state.highest_qualification
                              ? this.state.highest_qualification
                              : "---"}
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <p className="param">Employee In</p>
                        </div>
                        <div className="col-6">
                          <p className="value">
                            : &nbsp;&nbsp;
                            {this.state.employee_in
                              ? this.state.employee_in
                              : "---"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="about-self">
                    <div className="details-header d-flex justify-content-between align-items-center">
                      <div className="d-flex align-items-center">
                        <box-icon
                          type="solid"
                          name="user"
                          size={"19px"}
                        ></box-icon>
                        &nbsp;&nbsp;
                        <h5 className="mb-0 pb-0">Partner Preferences </h5>
                      </div>
                    </div>
                    <div className="all-list">
                      <div className="row">
                        <div className="col-6">
                          <p className="param">Age Range</p>
                        </div>
                        <div className="col-6">
                          <p className="value">
                            : &nbsp;&nbsp;
                            {this.state.age_range
                              ? this.state.age_range
                              : "---"}
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <p className="param">Martial Status</p>
                        </div>
                        <div className="col-6">
                          <p className="value">
                            : &nbsp;&nbsp;
                            {this.state.partner_marital_status
                              ? this.state.partner_marital_status
                              : "---"}
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <p className="param">Mazhab</p>
                        </div>
                        <div className="col-6">
                          <p className="value">
                            : &nbsp;&nbsp;
                            {this.state.pmathhab_followed
                              ? this.state.pmathhab_followed
                              : "---"}
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <p className="param">Height</p>
                        </div>
                        <div className="col-6">
                          <p className="value">
                            : &nbsp;&nbsp;
                            {this.state.partner_height
                              ? this.state.partner_height
                              : "---"}
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <p className="param">Language</p>
                        </div>
                        <div className="col-6">
                          <p className="value">
                            : &nbsp;&nbsp;
                            {this.state.partner_language
                              ? this.state.partner_language
                              : "---"}
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <p className="param">Education</p>
                        </div>
                        <div className="col-6">
                          <p className="value">
                            : &nbsp;&nbsp;
                            {this.state.partner_education
                              ? this.state.partner_education
                              : "---"}
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <p className="param">Disability</p>
                        </div>
                        <div className="col-6">
                          <p className="value">
                            : &nbsp;&nbsp;
                            {this.state.partner_disability
                              ? this.state.partner_disability
                              : "---"}
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <p className="param">State</p>
                        </div>
                        <div className="col-6">
                          <p className="value">
                            : &nbsp;&nbsp;
                            {this.state.partner_state
                              ? this.state.partner_state
                              : "---"}
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <p className="param">Districts</p>
                        </div>
                        <div className="col-6">
                          <p className="value">
                            : &nbsp;&nbsp;
                            {this.state.partner_districts
                              ? this.state.partner_districts
                              : "---"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </Spin>
        <br />
        <br />
        <br />
        <br />
        <BottomNavigation />
      </div>
    );
  }
}
