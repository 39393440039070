import React, { Component } from "react";
import BottomNavigation from "./components/BottomNavigation";

export default class RecentActivation extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="see-all-page recent-activity-page">
        <div className="container">
          <div className="row head-sec align-items-center">
            <div className="col-8">
              <div className="left" style={{ justifyContent: "flex-start" }}>
                <a href="/dashboard">
                  <img
                    style={{ height: 18 }}
                    src="./assets/images/back-arrow.png"
                    alt="arrow"
                  />
                </a>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <h3>Recent Activities</h3>
              </div>
            </div>
            <div className="col-4">
              <div className="right">
                <img
                  src="./assets/images/search.png"
                  style={{ height: 23 }}
                  alt="search"
                />
                <img src="./assets/images/profile.png" alt="profile" />
              </div>
            </div>
          </div>
        </div>
        <div className="container pt-4">
          <div className="row">
            <div className="col-lg-12 px-3s">
              <div className="list-item">
                <img src="./assets/images/profile3.png" alt="profile" />
                <div className="content">
                  <p>Mohmaddi</p>
                  <span>Has logged in her account</span>
                </div>
              </div>
              <div className="list-item">
                <img src="./assets/images/profile4.png" alt="profile" />
                <div className="content">
                  <p>Heena</p>
                  <span>Has logged in her account</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <BottomNavigation active="" />
      </div>
    );
  }
}
