import React, { Component } from "react";

export default class Messages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeLink: "1",
    };
  }
  toggleTab = (param) => {
    this.setState({
      activeLink: param,
    });
  };
  render() {
    return (
      <div className="message-tab-list">
        <div className="custom-tab">
          <div
            className={this.state.activeLink == "1" ? `c-tab active` : "c-tab"}
            onClick={() => this.toggleTab(1)}
          >
            <p>Received</p>
          </div>
          <div
            className={this.state.activeLink == "2" ? `c-tab active` : "c-tab"}
            onClick={() => this.toggleTab(2)}
          >
            <p>Sent</p>
          </div>
        </div>
        <div className="custom-tab-content-wrapper">
          {this.state.activeLink == "1" && (
            <div className="c-tab-content">
              <div className="list-item">
                <img src="./assets/images/profile.png" className="profile" />
                <div className="inner-data">
                  <h5>John</h5>
                  <p>The lorem ipsum is a placeholder........ </p>
                </div>
                <div className="duration">
                  <span className="time">4:52 pm</span>
                  <br />
                  <img
                    style={{
                      height: 19,
                      position: "relative",
                      left: 36,
                    }}
                    src="./assets/images/count.png"
                  />
                </div>
              </div>
              <div className="list-item">
                <img src="./assets/images/profile2.png" className="profile" />
                <div className="inner-data">
                  <h5>Rohit</h5>
                  <p>The lorem ipsum is a placeholder........ </p>
                </div>
                <div className="duration">
                  <span className="time">4:52 pm</span>
                  <br />
                  <img
                    style={{
                      height: 19,
                      position: "relative",
                      left: 36,
                    }}
                    src="./assets/images/count.png"
                  />
                </div>
              </div>
            </div>
          )}
          {this.state.activeLink == "2" && (
            <div className="c-tab-content">
              <div className="list-item">
                <img src="./assets/images/profile.png" className="profile" />
                <div className="inner-data">
                  <h5>Sam</h5>
                  <p>The lorem ipsum is a placeholder........ </p>
                </div>
                <div className="duration">
                  <span className="time">4:52 pm</span>
                  <br />
                  <img
                    style={{
                      height: 19,
                      position: "relative",
                      left: 36,
                    }}
                    src="./assets/images/count.png"
                  />
                </div>
              </div>
              <div className="list-item">
                <img src="./assets/images/profile2.png" className="profile" />
                <div className="inner-data">
                  <h5>Ganesh</h5>
                  <p>The lorem ipsum is a placeholder........ </p>
                </div>
                <div className="duration">
                  <span className="time">4:52 pm</span>
                  <br />
                  <img
                    style={{
                      height: 19,
                      position: "relative",
                      left: 36,
                    }}
                    src="./assets/images/count.png"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
