import React, { Component } from "react";
import BottomNavigation from "./components/BottomNavigation";

export default class SeeAll extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [
        {
          img: "./assets/images/home-slider/1.png",
          name: "A Soni, 28",
          place: "Chandigarh & Shimla",
        },
        {
          img: "./assets/images/home-slider/2.png",
          name: "A Soni, 28",
          place: "Chandigarh & Shimla",
        },
        {
          img: "./assets/images/home-slider/3.png",
          name: "A Soni, 28",
          place: "Chandigarh & Shimla",
        },
        {
          img: "./assets/images/home-slider/4.png",
          name: "A Soni, 28",
          place: "Chandigarh & Shimla",
        },
        {
          img: "./assets/images/home-slider/5.png",
          name: "A Soni, 28",
          place: "Chandigarh & Shimla",
        },
      ],
    };
  }
  render() {
    return (
      <div className="see-all-page">
        <div className="container">
          <div className="row head-sec align-items-center">
            <div className="col-8">
              <div className="left">
                <a href="/dashboard">
                  <img
                    style={{ height: 18 }}
                    src="./assets/images/back-arrow.png"
                    alt="arrow"
                  />
                </a>
                <h3>Profile Viewed By Me</h3>
              </div>
            </div>
            <div className="col-4">
              <div className="right">
                <img
                  src="./assets/images/search.png"
                  style={{ height: 23 }}
                  alt="search"
                />
                <img src="./assets/images/profile.png" alt="profile" />
              </div>
            </div>
          </div>
          <div className="row profle-image-list">
            {this.state.list &&
              this.state.list.map((item, index) => {
                return (
                  <div className="col-6">
                    <div className="single-item">
                      <img src={item.img} alt="profile-image" />
                      <p>{item.name}</p>
                      <span>{item.place}</span>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        <BottomNavigation active="people" />
      </div>
    );
  }
}
