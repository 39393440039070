import React, { Component } from "react";
import axios from "axios";
import URLs from "../../utils/apis";
import { message, Spin, Slider, Select } from "antd";

const { Option } = Select;

export default class Registration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      age: [23, 27],
      pmarital_status: "",
      pmathhab_followed: "",
      pheight: "",
      planguages: "",
      peducation: "",
      pdisability: "",
      pnationality: "",
      presiding_country: "",
      ppreferred_cities: "",
      loading: false,
      pstate_id: "",
      pdistrict_id: [],
      ptaluka_id: "",
      profile_image: "",
      loading: false,
      disability_list: [
        "Paraplegic",
        "Locomotor",
        "Deaf & Dumb",
        "Dwarfism",
        "Hearing Impairment",
        "Paralysis",
        "Blind",
        "Low vision",
        "Mental Retardation",
        "Polio",
        "Hearing impairment",
        "Cerebral Palsy",
        "Intellectual Disability",
        "Acid attack victim",
        "Autism spectrum disorder",
        "Chronic neurological conditions",
        "Parkinson’s disease",
        "Haemophilia",
        "Leprosy cured person",
        "Muscular dystrophy",
        "Sickle cell disease",
        "Specific learning disabilities",
        "Speech & Language",
        "Thalassemia",
        "Multiple disability",
      ],
      state_list: [],
      district_list: [],
      taluka_list: [],
      languages: [
        "Hindi",
        "Marathi",
        "Urdu",
        "English",
        "Bengali",
        "Telugu",
        "Tamil",
        "Gujarati",
        "Kannada",
        "Odia",
        "Punjabi",
        "Malayalam",
        "Assamese",
        "Maithili",
        "Sanskrit",
      ],
      mobile: "",
      got_user_id: false,
    };
  }
  componentDidMount() {
    console.log("props", this.props);
    this.get_state_list();
    if (this.state.got_user_id == false) {
      if (localStorage.getItem("registered_user_id")) {
        this.setState(
          {
            got_user_id: true,
          },
          () => this.get_user_details()
        );
      }
    }
  }
  get_user_details = () => {
    let params = {
      user_id: localStorage.getItem("registered_user_id"),
    };
    axios
      .post(URLs.get_register_details, params)
      .then((response) => {
        if (response.data.status == "1") {
          if (response.data.data.length > 0) {
            this.setState({
              mobile: response.data.data[0].mobile,
            });
          }
          return;
        } else {
        }
      })
      .catch((error) => {});
  };
  get_state_list = () => {
    axios
      .get(URLs.get_state_list)
      .then((response) => {
        if (response.data.status == "1") {
          this.setState({
            state_list: response.data.data,
          });
          return;
        } else {
          return;
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
        message.warning("Please try again.");
      });
  };
  get_district_list = () => {
    let params = {
      state_id: this.state.pstate_id,
    };
    axios
      .post(URLs.get_district_list, params)
      .then((response) => {
        if (response.data.status == "1") {
          this.setState({
            district_list: response.data.data,
            pdistrict_id: [],
          });
          return;
        } else {
          return;
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
        message.warning("Please try again.");
      });
  };
  get_taluka_list = () => {
    let params = {
      d_id: this.state.pdistrict_id,
    };
    axios
      .post(URLs.get_taluka_list, params)
      .then((response) => {
        if (response.data.status == "1") {
          this.setState({
            taluka_list: response.data.data,
          });
          return;
        } else {
          return;
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
        message.warning("Please try again.");
      });
  };
  save = () => {
    let formdata = new FormData();
    formdata.append("age", this.state.age);
    formdata.append(
      "pmarital_status",
      this.state.pmarital_status ? this.state.pmarital_status : ""
    );
    formdata.append(
      "pmathhab_followed",
      this.state.pmathhab_followed ? this.state.pmathhab_followed : ""
    );
    formdata.append("pheight", this.state.pheight ? this.state.pheight : "");
    formdata.append(
      "planguages",
      this.state.planguages ? this.state.planguages : ""
    );
    formdata.append(
      "peducation",
      this.state.peducation ? this.state.peducation : ""
    );
    formdata.append(
      "pdisability",
      this.state.pdisability ? this.state.pdisability : ""
    );
    formdata.append(
      "pstate_id",
      this.state.pstate_id ? this.state.pstate_id : ""
    );
    formdata.append("pdistrict_id", JSON.stringify(this.state.pdistrict_id));
    formdata.append("ptaluka_id", "0");
    // formdata.append("profile_image", this.state.profile_image);

    formdata.append("step", "6");
    formdata.append("user_id", localStorage.getItem("registered_user_id"));
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        // "Content-Type": "application/json",
      },
    };
    this.setState({ loading: true });
    axios
      .post(URLs.register, formdata, config)
      .then((response) => {
        if (response.data.status == "1") {
          message.success("You are registered scuccessfully.");
          // message.success(response.data.message);
          this.setState(
            {
              loading: false,
            },
            () => {
              localStorage.removeItem("registered_user_id");
              this.login();
            }
          );

          return;
        } else {
          this.setState({
            loading: false,
          });
          message.warning(response.data.message);
          return;
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
        message.warning("Please try again.");
      });
  };
  onChange = (checked) => {
    this.setState({
      age: checked,
    });
  };
  login = () => {
    if (!this.props.mobile) {
      message.warning("Mobile No is required.");
      return;
    }
    if (this.props.mobile.length != 10) {
      message.warning("Invalid Mobile no format.");
      return;
    }
    // if (!this.state.password) {
    //   message.warning("Password is required.");
    //   return;
    // }
    let params = {
      mobile: this.props.mobile,
      // password: this.state.password,
    };
    this.setState({ loading: true });
    axios
      .post(URLs.login, params)
      .then((response) => {
        if (response.data.status == "1") {
          localStorage.setItem("r_name", response.data.data[0].name);
          localStorage.setItem("r_gender", response.data.data[0].gender);
          localStorage.setItem("r_id", response.data.data[0].id);
          // message.success(response.data.message);
          this.setState({
            loading: false,
            showPassword: true,
          });
          window.location.assign("/dashboard");
          return;
        } else {
          this.setState({
            loading: false,
            modalStatus: true,
          });
          // message.warning(response.data.message);
          return;
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
        message.warning("Please try again.");
      });
  };
  render() {
    return (
      <div className="container px-0 registration-page">
        <Spin spinning={this.state.loading}>
          <div className="row">
            <div className="col-lg-12">
              <h2>
                <b>Partner Preferences</b>
              </h2>
              <h6>Update based on your profile </h6>
              <div className="form-group">
                <label for="age">Age Range</label>
                <Slider
                  min={18}
                  max={55}
                  range
                  onChange={this.onChange}
                  value={this.state.age}
                />
                <p className="text-center">
                  {this.state.age[0]} - {this.state.age[1]}
                </p>
              </div>
              <div className="form-group">
                <label for="age">Marital Status</label>
                <select
                  value={this.state.pmarital_status}
                  onChange={(e) =>
                    this.setState({ pmarital_status: e.target.value })
                  }
                  name="status"
                  id="status"
                >
                  <option value="select">select</option>
                  <option value="never married">Never Married</option>
                  <option value="widowed">Widowed</option>
                  <option value="divorced">Divorced</option>
                  <option value="awt divorced">Awaiting divorced</option>
                </select>
              </div>
              <div className="form-group">
                <label for="math'hab followed">Math'hab Followed</label>
                <select
                  name="math"
                  id="math"
                  value={this.state.pmathhab_followed}
                  onChange={(e) =>
                    this.setState({ pmathhab_followed: e.target.value })
                  }
                >
                  <option value="">Select</option>
                  <option value="Hanafi">Hanafi</option>
                  <option value="Hanbali">Hanbali</option>
                  <option value="Maliki">Maliki</option>
                  <option value="Shafi">Shafi</option>
                  <option value="Shia">Shia</option>
                  <option value="Just Muslim">Just Muslim</option>
                  <option value="Other">Other</option>
                </select>
              </div>
              <div className="form-group">
                <label for="height">Height</label>
                <select
                  value={this.state.pheight}
                  onChange={(e) => {
                    this.setState({
                      pheight: e.target.value,
                    });
                  }}
                  name="name"
                  id="name"
                >
                  <option value="Select">Select</option>
                  <option value="4ft 6in/137cms">4ft 6in/137cms</option>
                  <option value="4ft 7in/139cms">4ft 7in/139cms</option>
                  <option value="4ft 8in/142cms">4ft 8in/142cms</option>
                  <option value="4ft 9in/144cms">4ft 9in/144cms</option>
                  <option value="4ft 10in/147cms">4ft 10in/147cms</option>
                  <option value="4ft 11in/149cms">4ft 11in/149cms</option>
                  <option value="5ft/152cms">5ft/152cms</option>
                  <option value="5ft 1in/154cms">5ft 1in/152cms</option>
                  <option value="5ft 2in/157cms">5ft 2in/157cms</option>
                  <option value="5ft 3in/160cms">5ft 3in/160cms</option>
                  <option value="5ft 4in/162cms">5ft 4in/162cms</option>
                  <option value="5ft 5in/165cms">5ft 5in/165cms</option>
                  <option value="5ft 6in/167cms">5ft 6in/167cms</option>
                  <option value="5ft 7in/170cms">5ft 7in/170cms</option>
                  <option value="5ft 8in/172cms">5ft 8in/172cms</option>
                  <option value="5ft 9in/175cms">5ft 9in/175cms</option>
                  <option value="5ft 10in/177cms">5ft 10in/177cms</option>
                  <option value="5ft 11in/180cms">5ft 11in/180cms</option>
                  <option value="6ft/182cms">6ft/182cms</option>
                  <option value="6ft 1in/185cms">6ft 1in/185cms</option>
                  <option value="6ft 2in/187cms">6ft 2in/187cms</option>
                  <option value="6ft 3in/190cms">6ft 3in/190cms</option>
                  <option value="6ft 4in/193cms">6ft 4in/193cms</option>
                  <option value="6ft 5in/195cms">6ft 5in/195cms</option>
                  <option value="6ft 6in/198cms">6ft 6in/198cms</option>
                  <option value="6ft 7in/200cms">6ft 7in/200cms</option>
                  <option value="6ft 8in/203cms">6ft 8in/203cms</option>
                  <option value="6ft 9in/205cms">6ft/205cms</option>
                  <option value="6ft 10in/208cms">6ft/208cms</option>
                  <option value="6ft 11in/210cms">6ft/210cms</option>
                  <option value="7in/213cms">7ft/213cms</option>
                </select>
              </div>
              <div className="form-group">
                <label for="language">Languages</label>
                <select
                  data-placeholder="language"
                  value={this.state.planguages}
                  onChange={(e) =>
                    this.setState({ planguages: e.target.value })
                  }
                >
                  <option value="">Select</option>
                  {this.state.languages &&
                    this.state.languages.map((item, index) => {
                      return (
                        <option value={item} key={index}>
                          {item}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div className="form-group">
                <label for="height">Education</label>
                <input
                  type="text"
                  name="email"
                  size="30"
                  maxlength="50"
                  placeholder="Education"
                  value={this.state.peducation}
                  onChange={(e) => {
                    this.setState({
                      peducation: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="form-group">
                <label for="height">Disability</label>
                <select
                  name="name"
                  id="name"
                  value={this.state.pdisability}
                  onChange={(e) =>
                    this.setState({ pdisability: e.target.value })
                  }
                >
                  <option value="">Select</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </div>
              <div className="form-group">
                <label for="height">State</label>
                <select
                  value={this.state.pstate_id}
                  onChange={(e) => {
                    this.setState(
                      {
                        pstate_id: e.target.value,
                      },
                      () => this.get_district_list()
                    );
                  }}
                  style={{ width: "100%" }}
                  name="name"
                  id="name"
                >
                  <option value="">Select</option>
                  {this.state.state_list &&
                    this.state.state_list.map((item, index) => {
                      return (
                        <option value={item.state_id} key={index}>
                          {item.state_name}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div className="form-group">
                <label for="country">District</label>
                <Select
                  value={this.state.pdistrict_id}
                  onChange={(value) => {
                    this.setState({
                      pdistrict_id: value,
                    });
                  }}
                  style={{ width: "100%" }}
                  mode="multiple"
                  allowClear
                  name="name"
                  id="name"
                  placeholder="Select District"
                >
                  {this.state.district_list &&
                    this.state.district_list.map((item, index) => {
                      return (
                        <option value={item.district_id} key={index}>
                          {item.name}
                        </option>
                      );
                    })}
                </Select>
              </div>
              <div className="form-group"></div>
              <button
                className="submit-button"
                type="button"
                onClick={() => this.save()}
              >
                Save
              </button>
              <button
                className="submit-button"
                type="button"
                onClick={() => this.props.change(2)}
              >
                Back
              </button>
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}
