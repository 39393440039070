import React, { Component } from "react";

export default class Pending extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [
        {
          img: "./assets/images/home-slider/1.png",
          name: "A Soni, 28",
          place: "Chandigarh & Shimla",
        },
      ],
    };
  }
  render() {
    return (
      <div className="see-all-page" style={{ marginTop: 30 }}>
        <div className="container">
          <div className="row profle-image-list">
            {this.state.list &&
              this.state.list.map((item, index) => {
                return (
                  <div className="col-6">
                    <div className="single-item">
                      <img src={item.img} alt="profile-image" />
                      <p>{item.name}</p>
                      <span>{item.place}</span>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    );
  }
}
