import React, { Component } from "react";
import BottomNavigation from "./components/BottomNavigation";

export default class Privacy extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="see-all-page">
        <div className="container">
          <div className="row head-sec align-items-center">
            <div className="col-8">
              <div className="left">
                <a href="/dashboard">
                  <img
                    style={{ height: 18 }}
                    src="./assets/images/back-arrow.png"
                    alt="arrow"
                  />
                </a>
                <h3>Recent Activities</h3>
              </div>
            </div>
            <div className="col-4">
              <div className="right">
                <img
                  src="./assets/images/search.png"
                  style={{ height: 23 }}
                  alt="search"
                />
                <img src="./assets/images/profile.png" alt="profile" />
              </div>
            </div>
          </div>
        </div>
        <BottomNavigation active="people" />
      </div>
    );
  }
}
