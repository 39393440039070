import React, { Component } from "react";
import Sidebar from "./Sidebar";
import $ from "jquery";
import URLs from "../utils/apis";
import axios from "axios";
export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_active: false,
    };
  }
  toggleSidebar = () => {
    // /* background: #00000078;
    if ($("#sidebar").hasClass("hide-sidebar")) {
      $("#sidebar").removeClass("hide-sidebar").addClass("show-sidebar");
      setTimeout(() => {
        $(".sidebar-section").css("background", "rgb(0 0 0 / 28%)");
      }, 350);
    } else {
      $(".sidebar-section").css("background", "transparent");
      setTimeout(() => {
        $("#sidebar").addClass("hide-sidebar").removeClass("show-sidebar");
      }, 140);
    }
  };
  componentDidMount() {
    this.get_profile_details();
  }
  get_profile_details = () => {
    let user_id = localStorage.getItem("r_id");
    let params = {
      id: user_id,
    };
    axios
      .post(URLs.get_profile_details, params)
      .then((response) => {
        if (response.data.status == "1") {
          if (response.data.data.images.length > 0) {
            let url = "";
            if (response.data.data.images[0].image) {
              url = URLs.image_base_url + response.data.data.images[0].image;
            }
            this.setState({
              profile_image:
                response.data.data.images[0].image != "" ||
                response.data.data.images[0].image != null
                  ? url
                  : "",
            });
          }
          this.setState({
            username: response.data.data.user_details.name,
          });
        }
      })
      .catch((error) => {
        console.error("Warning:", error);
      });
  };
  render() {
    return (
      <div className="header fixed-head-section" style={{background:"#fff"}}>
        <div className="inner toggle-sidebar">
          <div className="d-flex">
            <box-icon
              name="menu-alt-left"
              color="#000"
              size={"30px"}
              onClick={() => this.toggleSidebar()}
              style={{
                position: "relative",
                top: 4,
              }}
            ></box-icon>
            {/* <img
              onClick={() => this.toggleSidebar()}
              style={{
                width: 21,
                height: 25,
                position: "relative",
                top: 4,
              }}
              src="./assets/images/menu.png"
            /> */}
            <div>
              <img
                style={{
                    width: "137px",
                    height: 30,
                    maxHeight: 55,
                    minHeight: 10,
                    borderRadius: 0,
                    position: "relative",
                    top: 6,
                    objectFit: "initial",
                    left: 18
                }}
                src="./assets/images/logo.png"
              />
            </div>
          </div>

          <img
            src={
              this.state.profile_image
                ? this.state.profile_image
                : "./assets/images/no-image-available.jpg"
            }
          />
        </div>
        <div id="sidebar" className={`sidebar-section hide-sidebar`}>
          <Sidebar />
          <div className="closeMenu" onClick={() => this.toggleSidebar()}>
            <box-icon
              name="x"
              size={"28px"}
              style={{ marginTop: 5, marginLeft: 5 }}
            ></box-icon>
          </div>
        </div>
      </div>
    );
  }
}
