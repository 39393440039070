import React, { Component } from "react";
import { Modal } from "antd";
import Step1Basic from "./components/registerComponents/Step1Basic";
import Step2PersonalDetiails from "./components/registerComponents/Step2PersonalDetiails";
import Step3Appearance from "./components/registerComponents/Step3Appearance";
import Step4Place from "./components/registerComponents/Step4Place";
import Step5PartnerPreference from "./components/registerComponents/Step6PartnerPreference";
import Step5YourQualification from "./components/registerComponents/Step5YourQualification";
import Step6PartnerPreference from "./components/registerComponents/Step6PartnerPreference";
import { message, Spin } from "antd";
import URLs from "./utils/apis";
import axios from "axios";

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalStatus: false,
      step: 1,
      mobile: "",
      password: "",
      showPassword: false,
      loading: false,
      user_id: "",
    };
  }
  componentDidMount() {
    localStorage.removeItem("registered_user_id");
    if (localStorage.getItem("r_id")) {
      window.location.assign("/dashboard");
      return;
    }
  }
  openModal = () => {
    this.setState({ modalStatus: true });
  };
  closeModal = () => {
    this.setState({ modalStatus: false });
  };
  changeStep2 = (step, user_id) => {
    this.setState({ step: step, user_id: user_id });
  };
  changeStep = (step) => {
    this.setState({ step: step });
  };
  check_for_user = () => {
    if (!this.state.mobile) {
      message.warning("Mobile No is required.");
      return;
    }
    // if (this.state.mobile.length != 10) {
    //   message.warning("Invalid Mobile no format.");
    //   return;
    // }
    let params = {
      mobile: this.state.mobile,
    };
    this.setState({ loading: true });
    axios
      .post(URLs.check_for_user, params)
      .then((response) => {
        if (response.data.status == "1") {
          // message.success(response.data.message);
          this.setState({
            loading: false,
            showPassword: true,
          });
          return;
        } else {
          this.setState(
            {
              loading: false,
            },
            () => this.openModal()
          );
          // message.warning(response.data.message);
          return;
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
        message.warning("Please try again.");
      });
  };
  login = () => {
    if (!this.state.mobile) {
      message.warning("Mobile No is required.");
      return;
    }
    if (this.state.mobile.length != 10) {
      message.warning("Invalid Mobile no format.");
      return;
    }
    // if (!this.state.password) {
    //   message.warning("Password is required.");
    //   return;
    // }
    let params = {
      mobile: this.state.mobile,
      // password: this.state.password,
    };
    this.setState({ loading: true });
    axios
      .post(URLs.login, params)
      .then((response) => {
        if (response.data.status == "1") {
          localStorage.setItem("r_name", response.data.data[0].name);
          localStorage.setItem("r_gender", response.data.data[0].gender);
          localStorage.setItem("r_id", response.data.data[0].id);
          message.success(response.data.message);
          this.setState({
            loading: false,
            showPassword: true,
          });
          window.location.assign("/dashboard");
          return;
        } else {
          this.setState({
            loading: false,
            modalStatus: true,
          });
          // message.warning(response.data.message);
          return;
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
        message.warning("Please try again.");
      });
  };
  render() {
    return (
      <div className="container find-best-partner">
        <Spin spinning={this.state.loading}>
          <div className="row">
            <div className="col-lg-12">
              <img className="main-img" src="./assets/images/logo2.png" />
              <h2>Find Your Best Partner </h2>
              <span>Login or signup </span>
              <input
                type="text"
                id="name"
                name="name"
                size="30"
                maxLength={10}
                placeholder="Enter Mobile Number"
                value={this.state.mobile}
                onChange={(e) => {
                  let value = e.target.value.slice(0, 10);
                  this.setState({
                    mobile: value,
                  });
                }}
              />
              <button onClick={() => this.login()} type="button">
                Login
              </button>
              <p className="or-text">OR</p>
              <div className="social-icons">
                <div className="icon">
                  <img src="./assets/images/social-icons/google.png" />
                </div>
                <div className="icon">
                  <img src="./assets/images/social-icons/facebook.png" />
                </div>
                <div className="icon">
                  <img src="./assets/images/social-icons/apple.png" />
                </div>
              </div>
              <p className="bottom-text">
                By continuing you about to agree to the terms and
                conditions,privacy policay
              </p>
            </div>
          </div>
        </Spin>

        <Modal
          visible={this.state.modalStatus}
          okText={"Continue"}
          onOk={() => this.closeModal()}
          onCancel={() => this.closeModal()}
          footer={[]}
        >
          {this.state.step == "1" && (
            <Step1Basic
              mobile={this.state.mobile}
              change={this.changeStep2}
              user_id={this.state.user_id}
            />
          )}
          {this.state.step == "2" && (
            <Step2PersonalDetiails
              change={this.changeStep}
              user_id={this.state.user_id}
            />
          )}
          {this.state.step == "3" && (
            <Step3Appearance
              change={this.changeStep}
              user_id={this.state.user_id}
            />
          )}
          {this.state.step == "4" && (
            <Step4Place change={this.changeStep} user_id={this.state.user_id} />
          )}
          {this.state.step == "5" && (
            <Step5YourQualification
              change={this.changeStep}
              user_id={this.state.user_id}
            />
          )}
          {this.state.step == "6" && (
            <Step6PartnerPreference
              mobile={this.state.mobile}
              change={this.changeStep}
              user_id={this.state.user_id}
            />
          )}
        </Modal>
      </div>
    );
  }
}
