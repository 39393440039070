import React, { Component } from "react";
import axios from "axios";
import URLs from "../../utils/apis";
import { message, Spin } from "antd";

export default class Registration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      highest_qualification: "",
      employee_in: "",
      occupation: "",
      income_in_currency: "",
      income_in_anually: "",
      income_in_monthly: "",
      family_status: "",
      loading: false,
      got_user_id: false,
    };
  }
  componentDidMount() {
    if (this.state.got_user_id == false) {
      if (localStorage.getItem("registered_user_id")) {
        this.setState(
          {
            got_user_id: true,
          },
          () => this.get_user_details()
        );
      }
    }
  }
  componentDidCatch() {
    console.log("personal updated this.props.user_id", this.props.user_id);
    if (this.state.got_user_id == false) {
      if (localStorage.getItem("registered_user_id")) {
        this.setState(
          {
            got_user_id: true,
          },
          () => this.get_user_details()
        );
      }
    }
  }
  get_user_details = () => {
    let params = {
      user_id: localStorage.getItem("registered_user_id"),
    };
    axios
      .post(URLs.get_register_details, params)
      .then((response) => {
        if (response.data.status == "1") {
          if (response.data.data.length > 0) {
            console.log("response.data.data", response.data.data);
            this.setState({
              highest_qualification:
                response.data.data[0].highest_qualification,
              employee_in: response.data.data[0].employee_in,
            });
          }
          return;
        } else {
        }
      })
      .catch((error) => {});
  };
  save = () => {
    let params = {
      highest_qualification: this.state.highest_qualification
        ? this.state.highest_qualification
        : "",
      employee_in: this.state.employee_in ? this.state.employee_in : "",
      // occupation: this.state.occupation,
      // income_in_currency: this.state.income_in_currency,
      // income_in_anually: this.state.income_in_anually,
      // income_in_monthly: this.state.income_in_monthly,
      // family_status: this.state.family_status,
      user_id: localStorage.getItem("registered_user_id"),
      step: "5",
    };
    this.setState({ loading: true });
    axios
      .post(URLs.register, params)
      .then((response) => {
        if (response.data.status == "1") {
          message.success(response.data.message);
          this.setState(
            {
              loading: false,
            },
            () => this.props.change(6)
          );
          return;
        } else {
          this.setState({
            loading: false,
          });
          message.warning(response.data.message);
          return;
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
        message.warning("Please try again.");
      });
  };
  render() {
    return (
      <div className="container px-0 registration-page">
        <div className="row">
          <div className="col-lg-12">
            <h2>
              <b>About your Qualification</b>
            </h2>
            <h6>Profile with more information gets better response</h6>
            <div className="form-group">
              <label for="qualification">Highest Qualification</label>
              <input
                type="text"
                name="email"
                size="30"
                maxlength="50"
                placeholder="Highest Qualification"
                value={this.state.highest_qualification}
                onChange={(e) => {
                  this.setState({
                    highest_qualification: e.target.value,
                  });
                }}
              />
            </div>
            <div className="form-group">
              <label for="employee">Employee In</label>
              <input
                type="text"
                name="email"
                size="30"
                maxlength="50"
                placeholder="Employee In"
                value={this.state.employee_in}
                onChange={(e) => {
                  this.setState({
                    employee_in: e.target.value,
                  });
                }}
              />
            </div>
            <button
              className="submit-button"
              type="button"
              onClick={() => this.save()}
            >
              Continue
            </button>
            <button
              className="submit-button"
              type="button"
              onClick={() => this.props.change(4)}
            >
              Back
            </button>
          </div>
        </div>
      </div>
    );
  }
}
