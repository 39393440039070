import React, { Component } from "react";
import axios from "axios";
import URLs from "../../utils/apis";
import { message, Spin } from "antd";

export default class Registration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      height: "",
      complexion: "",
      disability: "",
      loading: false,
      disability_list: [
        "Paraplegic",
        "Locomotor",
        "Deaf & Dumb",
        "Dwarfism",
        "Hearing Impairment",
        "Paralysis",
        "Blind",
        "Low vision",
        "Mental Retardation",
        "Polio",
        "Hearing impairment",
        "Cerebral Palsy",
        "Intellectual Disability",
        "Acid attack victim",
        "Autism spectrum disorder",
        "Chronic neurological conditions",
        "Parkinson’s disease",
        "Haemophilia",
        "Leprosy cured person",
        "Muscular dystrophy",
        "Sickle cell disease",
        "Specific learning disabilities",
        "Speech & Language",
        "Thalassemia",
        "Multiple disability",
      ],
      got_user_id: false,
    };
  }
  componentDidMount() {
    console.log("personal on mount this.props.user_id", this.props.user_id);
    if (this.state.got_user_id == false) {
      if (localStorage.getItem("registered_user_id")) {
        this.setState(
          {
            got_user_id: true,
          },
          () => this.get_user_details()
        );
      }
    }
  }
  componentDidCatch() {
    console.log("personal updated this.props.user_id", this.props.user_id);
    if (this.state.got_user_id == false) {
      if (localStorage.getItem("registered_user_id")) {
        this.setState(
          {
            got_user_id: true,
          },
          () => this.get_user_details()
        );
      }
    }
  }
  get_user_details = () => {
    let params = {
      user_id: localStorage.getItem("registered_user_id"),
    };
    axios
      .post(URLs.get_register_details, params)
      .then((response) => {
        if (response.data.status == "1") {
          if (response.data.data.length > 0) {
            this.setState({
              height: response.data.data[0].height,
              disability: response.data.data[0].disability,
            });
          }
          return;
        } else {
        }
      })
      .catch((error) => {});
  };
  save = () => {
    let params = {
      height: this.state.height ? this.state.height : "",
      // complexion: this.state.complexion,
      disability: this.state.disability ? this.state.disability : "",
      user_id: localStorage.getItem("registered_user_id"),
      step: "3",
    };
    this.setState({ loading: true });
    axios
      .post(URLs.register, params)
      .then((response) => {
        if (response.data.status == "1") {
          message.success(response.data.message);
          this.setState(
            {
              loading: false,
            },
            () => this.props.change(4)
          );
          return;
        } else {
          this.setState({
            loading: false,
          });
          message.warning(response.data.message);
          return;
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
        message.warning("Please try again.");
      });
  };
  render() {
    return (
      <div className="container px-0 registration-page">
        <Spin spinning={this.state.loading}>
          <div className="row">
            <div className="col-lg-12">
              <h2>
                <b>Your Appearance</b>
              </h2>
              <h6>Profile with more information gets better response</h6>
              <div className="form-group">
                <label for="height">Height</label>
                <select
                  value={this.state.height}
                  onChange={(e) => {
                    this.setState({
                      height: e.target.value,
                    });
                  }}
                  name="name"
                  id="name"
                >
                  <option value="Select">Select</option>
                  <option value="4ft 6in/137cms">4ft 6in/137cms</option>
                  <option value="4ft 7in/139cms">4ft 7in/139cms</option>
                  <option value="4ft 8in/142cms">4ft 8in/142cms</option>
                  <option value="4ft 9in/144cms">4ft 9in/144cms</option>
                  <option value="4ft 10in/147cms">4ft 10in/147cms</option>
                  <option value="4ft 11in/149cms">4ft 11in/149cms</option>
                  <option value="5ft/152cms">5ft/152cms</option>
                  <option value="5ft 1in/154cms">5ft 1in/152cms</option>
                  <option value="5ft 2in/157cms">5ft 2in/157cms</option>
                  <option value="5ft 3in/160cms">5ft 3in/160cms</option>
                  <option value="5ft 4in/162cms">5ft 4in/162cms</option>
                  <option value="5ft 5in/165cms">5ft 5in/165cms</option>
                  <option value="5ft 6in/167cms">5ft 6in/167cms</option>
                  <option value="5ft 7in/170cms">5ft 7in/170cms</option>
                  <option value="5ft 8in/172cms">5ft 8in/172cms</option>
                  <option value="5ft 9in/175cms">5ft 9in/175cms</option>
                  <option value="5ft 10in/177cms">5ft 10in/177cms</option>
                  <option value="5ft 11in/180cms">5ft 11in/180cms</option>
                  <option value="6ft/182cms">6ft/182cms</option>
                  <option value="6ft 1in/185cms">6ft 1in/185cms</option>
                  <option value="6ft 2in/187cms">6ft 2in/187cms</option>
                  <option value="6ft 3in/190cms">6ft 3in/190cms</option>
                  <option value="6ft 4in/193cms">6ft 4in/193cms</option>
                  <option value="6ft 5in/195cms">6ft 5in/195cms</option>
                  <option value="6ft 6in/198cms">6ft 6in/198cms</option>
                  <option value="6ft 7in/200cms">6ft 7in/200cms</option>
                  <option value="6ft 8in/203cms">6ft 8in/203cms</option>
                  <option value="6ft 9in/205cms">6ft/205cms</option>
                  <option value="6ft 10in/208cms">6ft/208cms</option>
                  <option value="6ft 11in/210cms">6ft/210cms</option>
                  <option value="7in/213cms">7ft/213cms</option>
                </select>
              </div>
              <div className="form-group">
                <label for="disability">Any Disability</label>
                <select
                  name="name"
                  value={this.state.disability}
                  onChange={(e) => {
                    this.setState({
                      disability: e.target.value,
                    });
                  }}
                  id="name"
                >
                  <option value="none">None</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </div>
              <button
                className="submit-button"
                type="button"
                onClick={() => this.save()}
              >
                Continue
              </button>
              <button
                className="submit-button"
                type="button"
                onClick={() => this.props.change(2)}
              >
                Back
              </button>
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}
