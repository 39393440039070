import React, { Component } from "react";

export default class BottomNavigation extends Component {
  render() {
    return (
      <div className="bottom-navigation">
        <div className="single">
          <a href="/dashboard">
            {this.props.active == "home" ? (
              <box-icon name="home" color="#d4a5ff" size={"28px"}></box-icon>
            ) : (
              <box-icon name="home" color="#d6d6d6" size={"28px"}></box-icon>
            )}
          </a>
        </div>
        {/* <div className="single">
          <a href="/see-all-list">
            {this.props.active == "people" ? (
              <img src="./assets/images/navigation/active/people.png" />
            ) : (
              <img src="./assets/images/navigation/inactive/people.png" />
            )}{" "}
          </a>
        </div> */}
        <div className="single">
          <a href="/messages">
            {this.props.active == "messages" ? (
              <box-icon
                name="message-rounded-dots"
                color="#d4a5ff"
                size={"30px"}
              ></box-icon>
            ) : (
              <box-icon
                name="message-rounded-dots"
                color="#d6d6d6"
                size={"30px"}
              ></box-icon>
            )}{" "}
          </a>
        </div>
        <div className="single">
          <a href="/profile">
            {this.props.active == "profile" ? (
              <box-icon name="user" size={"28px"} color="#d4a5ff"></box-icon>
            ) : (
              <box-icon name="user" size={"28px"} color="#d6d6d6"></box-icon>
            )}{" "}
          </a>
        </div>
        {/* <div className="single">
          <a href="/upgrade">
            {this.props.active == "upgrade" ? (
              <img src="./assets/images/navigation/active/king.png" />
            ) : (
              <img src="./assets/images/navigation/inactive/king.png" />
            )}{" "}
          </a>
        </div> */}
      </div>
    );
  }
}
