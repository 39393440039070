import React, { Component } from "react";
import Slider from "react-slick";

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default class SliderScreen extends Component {
  render() {
    var settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    return (
      <div className="container front-page-image-slider">
        <div className="row">
          <div className="col-lg-12">
            <Slider {...settings}>
              <div className="single">
                <div id="images">
                  <img
                    className="slide"
                    src="./assets/images/slider/slide1.png"
                  />
                  <h2>
                    Find Your <span>Partner</span>
                    <br /> With us
                  </h2>
                  <p>
                    Join us and socialize with <br />
                    millions of people
                  </p>
                  <button type="button">Continue</button>
                </div>
              </div>
              <div className="single">
                <div id="images">
                  <img
                    className="qumma"
                    src="./assets/images/slider/qumma.png"
                  />
                  <img
                    className="slide"
                    src="./assets/images/slider/slide2.png"
                  />
                  <h2>
                    Find Your Partner
                    <br /> With us
                  </h2>
                  <p>
                    Join us and socialize with <br />
                    millions of people
                  </p>
                  <button type="button">Continue</button>
                </div>
              </div>
              <div className="single">
                <div id="images">
                  <img
                    className="qumma"
                    src="./assets/images/slider/qumma.png"
                  />
                  <img
                    className="slide"
                    src="./assets/images/slider/slide3.png"
                  />
                  <h2>
                    Find Your Partner
                    <br /> With us
                  </h2>
                  <p>
                    Join us and socialize with <br />
                    millions of people
                  </p>
                  <button type="button">Continue</button>
                  <button
                    type="button"
                    onClick={() => window.location.assign("/login")}
                  >
                    Skip
                  </button>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>
    );
  }
}
