import { message, Spin, Upload, Button } from "antd";
import React, { Component } from "react";
import axios from "axios";
import URLs from "../../utils/apis";
import { UploadOutlined } from "@ant-design/icons";

export default class Registration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      country_list: ["India"],
      loading: false,
      profile_image: "",
      mother_toungue_list: [
        "Hindi",
        "Marathi",
        "Urdu",
        "English",
        "Bengali",
        "Telugu",
        "Tamil",
        "Gujarati",
        "Kannada",
        "Odia",
        "Punjabi",
        "Malayalam",
        "Assamese",
        "Maithili",
        "Sanskrit",
      ],
      created_by_list: [
        "Myself",
        "Daughter",
        "Son",
        "Sister",
        "Brother",
        "Relative",
        "Friend",
      ],
      email: "",
      password: "",
      gender: "",
      phone: "",
      name: "",
      country: "",
      mother_toungue: "",
      profile_created_by: "",
      got_user_id: false,
      profile_url: "",

      profile_image_url: "",
    };
  }
  componentDidMount() {
    console.log("this.props.user_id", this.props.user_id);
    this.setState({
      phone: this.props.mobile,
    });
    // if (this.state.got_user_id == false) {
    if (localStorage.getItem("registered_user_id")) {
      this.setState(
        {
          got_user_id: true,
        },
        () => this.get_user_details()
      );
    }
    // }
  }
  get_user_details = () => {
    let params = {
      user_id: localStorage.getItem("registered_user_id"),
    };
    axios
      .post(URLs.get_register_details, params)
      .then((response) => {
        if (response.data.status == "1") {
          if (response.data.data.length > 0) {
            if (response.data.data[0].images.length > 0) {
              this.setState({
                profile_url:
                  URLs.image_base_url + response.data.data[0].images[0].image,
              });
            } else {
              this.setState({
                profile_url: "",
              });
            }
            this.setState({
              email: response.data.data[0].email,
              gender: response.data.data[0].gender,
              phone: response.data.data[0].phone,
              name: response.data.data[0].name,
              mother_toungue: response.data.data[0].mother_toungue,
              profile_created_by: response.data.data[0].profile_created_by,
            });
          }
          return;
        } else {
        }
      })
      .catch((error) => {});
  };
  componentDidCatch() {
    console.log("updated this.props.user_id", this.props.user_id);
    if (this.state.got_user_id == false) {
      if (this.props.user_id) {
        this.setState(
          {
            got_user_id: true,
          },
          () => this.get_user_details()
        );
      }
    }
  }
  validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };
  save = () => {
    if (!this.state.name) {
      message.warning("Name is required.");
      return;
    }
    if (this.state.email) {
      if (this.validateEmail(this.state.email) == false) {
        message.warning("Please enter valid format of email address");
        return;
      }
    }
    if (!this.state.phone) {
      message.warning("Phone no is required.");
      return;
    } else if (!this.state.gender) {
      message.warning("Gender is required.");
      return;
    } else if (!this.state.mother_toungue) {
      message.warning("Mother toungue is required.");
      return;
    } else if (!this.state.profile_created_by) {
      message.warning("Profile created by is required.");
      return;
    }
    if (!this.state.profile_url) {
      if (!this.state.profile_image) {
        message.warning("Profile image is required.");
        return;
      }
    }
    let formdata = new FormData();
    formdata.append("email", this.state.email ? this.state.email : "");
    formdata.append("gender", this.state.gender);
    formdata.append("phone", this.state.phone);
    formdata.append("name", this.state.name);
    formdata.append("mother_toungue", this.state.mother_toungue);
    formdata.append("profile_created_by", this.state.profile_created_by);
    formdata.append("profile_image", this.state.profile_image);
    formdata.append(
      "user_id",
      localStorage.getItem("registered_user_id")
        ? localStorage.getItem("registered_user_id")
        : 0
    );
    formdata.append("step", "1");

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    this.setState({ loading: true });
    axios
      .post(URLs.register, formdata, config)
      .then((response) => {
        if (response.data.status == "1") {
          message.success(response.data.message);
          if (response.data.action == "add") {
            localStorage.setItem("registered_user_id", response.data.secret);
          }
          this.setState(
            {
              loading: false,
            },
            () => this.props.change(2, response.data.secret)
          );
          return;
        } else {
          this.setState({
            loading: false,
          });
          message.warning(response.data.message);
          return;
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
        message.warning("Please try again.");
      });
  };
  render() {
    const props = {
      name: "file",
      action: "https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload",
      headers: {
        authorization: "authorization-text",
      },
      onChange(info) {
        if (info.file.status !== "uploading") {
          console.log(info.file, info.fileList);
        }
        if (info.file.status === "done") {
          message.success(`${info.file.name} file uploaded successfully`);
        } else if (info.file.status === "error") {
          message.error(`${info.file.name} file upload failed.`);
        }
      },
    };
    return (
      <div className="container px-0 registration-page step1">
        <Spin spinning={this.state.loading}>
          <div className="row">
            <div className="col-lg-12">
              <h2>
                <b>
                  Registration - <br />
                  Basic Details
                </b>
              </h2>{" "}
              <h6>Update these details to get suitable matches</h6> <br />
              <div className="form-group">
                <label>
                  Name <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  name="email"
                  size="30"
                  maxlength="50"
                  placeholder="Full Name"
                  value={this.state.name}
                  onChange={(e) => {
                    this.setState({
                      name: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="form-group">
                <label>Email</label>
                <input
                  type="text"
                  id="email"
                  name="email"
                  size="30"
                  maxlength="50"
                  placeholder="Email Address"
                  value={this.state.email}
                  onChange={(e) => {
                    this.setState({
                      email: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="form-group">
                <label for="phone">
                  Enter your phone number <span className="text-danger">*</span>
                </label>{" "}
                <br />
                <input
                  type="number"
                  id="phone"
                  name="phone"
                  disabled={true}
                  style={{ background: "#ddd" }}
                  maxLength={10}
                  pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                  value={this.state.phone}
                  onChange={(e) => {
                    this.setState({
                      phone: e.target.value,
                    });
                  }}
                />{" "}
              </div>
              <div className="form-group">
                <label for="Gender">
                  Gender Preference <span className="text-danger">*</span>
                </label>{" "}
                <br />
                <div className="gender-option">
                  <label for="male">Male</label>
                  <input
                    type="radio"
                    onClick={() => {
                      this.setState({
                        gender: "Male",
                      });
                    }}
                    checked={this.state.gender == "Male" ? true : false}
                    id="male"
                    name="gender"
                    value="Male"
                  />
                </div>
                <div className="gender-option">
                  <label for="female" className="gender-option">
                    Female
                  </label>
                  <input
                    onClick={() => {
                      this.setState({
                        gender: "Female",
                      });
                    }}
                    type="radio"
                    id="female"
                    name="gender"
                    value="Female"
                    checked={this.state.gender == "Female" ? true : false}
                  />
                </div>
              </div>
              <div className="form-group">
                <label for="Mother Tounge">
                  Mother Tounge <span className="text-danger">*</span>
                </label>{" "}
                <br />
                <select
                  value={this.state.mother_toungue}
                  onChange={(e) => {
                    this.setState({
                      mother_toungue: e.target.value,
                    });
                  }}
                  data-placeholder="Mother Tounge"
                >
                  <option value="">Select</option>
                  {this.state.mother_toungue_list &&
                    this.state.mother_toungue_list.map((item, index) => {
                      return (
                        <option value={item} key={index}>
                          {item}
                        </option>
                      );
                    })}
                </select>
              </div>
              {/*<div className="form-group">
                <label for="country">
                  Country <span className="text-danger">*</span>
                </label>
                <select
                  value={this.state.country}
                  onChange={(e) => {
                    this.setState({
                      country: e.target.value,
                    });
                  }}
                  id="country"
                  name="country"
                >
                  <option value="">Select</option>
                  {this.state.country_list &&
                    this.state.country_list.map((item, index) => {
                      return (
                        <option value={item} key={index}>
                          {item}
                        </option>
                      );
                    })}
                </select>
              </div>*/}
              <div className="form-group">
                <label for="Profile Created By">
                  Profile Created By <span className="text-danger">*</span>
                </label>{" "}
                <br />
                <select
                  value={this.state.profile_created_by}
                  onChange={(e) => {
                    this.setState({
                      profile_created_by: e.target.value,
                    });
                  }}
                  name="name"
                >
                  <option value="">Select</option>
                  {this.state.created_by_list &&
                    this.state.created_by_list.map((item, index) => {
                      return (
                        <option value={item} key={index}>
                          {item}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div className="form-group">
                <label for="height">
                  Profile Image <span className="text-danger">*</span>
                </label>
                <br />
                {!this.state.profile_image_url && this.state.profile_url && (
                  <div
                    style={{
                      padding: 5,
                      border: "1px solid #ddd",
                      width: "fit-content",
                    }}
                  >
                    <img
                      src={this.state.profile_url}
                      style={{ maxWidth: 66 }}
                    />
                  </div>
                )}
                {this.state.profile_image_url && (
                  <div
                    style={{
                      padding: 5,
                      border: "1px solid #ddd",
                      width: "fit-content",
                    }}
                  >
                    <img
                      src={this.state.profile_image_url}
                      alt="Profile"
                      style={{ maxWidth: 66 }}
                    />
                  </div>
                )}
                <input
                  type="file"
                  name="email"
                  size="30"
                  accept="image/*"
                  maxlength="50"
                  onChange={(e) => {
                    this.setState({
                      profile_image: e.target.files[0],
                    });
                    const file = e.target.files[0];
                    if (file) {
                      const reader = new FileReader();
                      reader.onload = (e) => {
                        this.setState({
                          profile_image_url: e.target.result,
                        });
                      };
                      reader.readAsDataURL(file);
                    }
                  }}
                />
                {/* <Upload {...props}>
                  <Button icon={<UploadOutlined />}>Click to Upload</Button>
                </Upload> */}
              </div>
              {/* <div className="form-group">
                <label>
                  Password <span className="text-danger">*</span>
                </label>{" "}
                <br />
                <input
                  type="password"
                  value={this.state.password}
                  onChange={(e) => {
                    this.setState({
                      password: e.target.value,
                    });
                  }}
                  name="passwd"
                  placeholder="Password"
                />
              </div> */}
              <button
                className="submit-button"
                type="button"
                onClick={() => this.save()}
              >
                Continue
              </button>{" "}
              <br />
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}
