import React, { Component } from "react";
import BottomNavigation from "./components/BottomNavigation";
import { Tabs } from "antd";
import Messages from "./components/index/Messages";
import Accepted from "./components/index/Accepted";
import Pending from "./components/index/Pending";

export default class Inbox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
    };
  }
  onChangeTab = (key) => {
    console.log(key);
  };
  render() {
    const items = [
      {
        key: "1",
        label: "Messsages",
        children: <Messages />,
      },
      {
        key: "2",
        label: "Accepted(2)",
        children: <Accepted />,
      },
      {
        key: "3",
        label: "Interested(3)",
        children: <Accepted />,
      },
      {
        key: "4",
        label: "Pending (1)",
        children: <Pending />,
      },
      {
        key: "5",
        label: "Consideration (1)",
        children: <Pending />,
      },
      {
        key: "6",
        label: "Declined (4)",
        children: <Accepted />,
      },
    ];
    return (
      <div className="inbox-section-list">
        <div className="container">
          <div className="row head-sec align-items-center">
            <div className="col-8">
              <div className="left">
                <a href="/dashboard">
                  <img
                    style={{ height: 18 }}
                    src="./assets/images/back-arrow.png"
                    alt="arrow"
                  />
                </a>
                &nbsp;&nbsp;&nbsp;
                <h3>Inbox</h3>
              </div>
            </div>
            <div className="col-4">
              <div className="right">
                <img
                  src="./assets/images/search.png"
                  style={{ height: 23 }}
                  alt="search"
                />
                <img src="./assets/images/profile2.png" alt="profile" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="msg_tab">
                <Tabs
                  defaultActiveKey="1"
                  items={items}
                  onChange={this.onChangeTab}
                />
              </div>
            </div>
          </div>
        </div>
        <BottomNavigation active="messages" />
      </div>
    );
  }
}
