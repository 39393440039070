import React, { Component } from "react";
import axios from "axios";
import URLs from "./utils/apis";
import { Button, Spin, message, Slider, Select, DatePicker } from "antd";
import moment from "moment";
import dayjs from "dayjs";

const { Option } = Select;

export default class OtherProfileUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      about_self: "",
      loading: false,
      mother_toungue_list: [
        "Hindi",
        "Marathi",
        "Urdu",
        "English",
        "Bengali",
        "Telugu",
        "Tamil",
        "Gujarati",
        "Kannada",
        "Odia",
        "Punjabi",
        "Malayalam",
        "Assamese",
        "Maithili",
        "Sanskrit",
      ],
      created_by_list: [
        "Myself",
        "Daughter",
        "Son",
        "Sister",
        "Brother",
        "Relative",
        "Friend",
      ],
      email: "",
      phone: "",
      name: "",
      mother_toungue: "",
      profile_created_by: "",

      // personal details
      marital_status: "",
      dob: null,
      born_or_reverted: "",
      mathhab_followed: "",
      own_age: 0,

      // appearance
      height: "",
      disability: "",

      // place
      state_id: "",
      district_id: "",
      taluka_id: "",
      address: "",
      state_list: [],
      district_list: [],
      taluka_list: [],

      highest_qualification: "",
      employee_in: "",

      pmarital_status: "",
      age_range: [23, 27],
      pmathhab_followed: "",
      pheight: "",
      planguages: "",
      peducation: "",
      pdisability: "",
      pstate_id: "",
      pdistrict_id: [],

      languages: [
        "Hindi",
        "Marathi",
        "Urdu",
        "English",
        "Bengali",
        "Telugu",
        "Tamil",
        "Gujarati",
        "Kannada",
        "Odia",
        "Punjabi",
        "Malayalam",
        "Assamese",
        "Maithili",
        "Sanskrit",
      ],
      parner_district_list: [],
    };
  }
  componentDidMount() {
    this.get_profile_details();
    if (this.props.type == "partner" || this.props.type == "place") {
      this.get_state_list();
    }
  }
  get_state_list = () => {
    axios
      .get(URLs.get_state_list)
      .then((response) => {
        if (response.data.status == "1") {
          this.setState({
            state_list: response.data.data,
          });
          return;
        } else {
          return;
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        // console.error("Warning:", error);
        message.warning("Please try again.");
      });
  };
  get_district_list = () => {
    let params = {
      state_id: this.state.state_id,
    };
    axios
      .post(URLs.get_district_list, params)
      .then((response) => {
        if (response.data.status == "1") {
          this.setState({
            district_list: response.data.data,
          });
          return;
        } else {
          return;
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        // console.error("Warning:", error);
        message.warning("Please try again.");
      });
  };
  get_taluka_list = () => {
    let params = {
      d_id: this.state.district_id,
    };
    axios
      .post(URLs.get_taluka_list, params)
      .then((response) => {
        if (response.data.status == "1") {
          this.setState({
            taluka_list: response.data.data,
          });
          return;
        } else {
          return;
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        // console.error("Warning:", error);
        message.warning("Please try again.");
      });
  };
  get_pdistrict_list = () => {
    let params = {
      state_id: this.state.pstate_id,
    };
    // console.log("params", params);
    axios
      .post(URLs.get_district_list, params)
      .then((response) => {
        if (response.data.status == "1") {
          // console.log("district list", response);
          this.setState({
            parner_district_list: response.data.data,
            // pdistrict_id: [],
          });
          return;
        } else {
          return;
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        // console.error("Warning:", error);
        message.warning("Please try again.");
      });
  };
  get_profile_details = () => {
    let params = {
      id: localStorage.getItem("r_id"),
    };
    this.setState({ loading: true });
    axios
      .post(URLs.get_profile_details, params)
      .then((response) => {
        // console.log("response.data", response.data.data.user_details);
        if (response.data.status == "1") {
          let url = "";
          let imgarr = [];
          for (let item of response.data.data.images) {
            item.url = "";
            if (item.image) {
              url = URLs.image_base_url + item.image;
              item.image_url = url;
            }
            imgarr.push(item);
          }
          if (response.data.data.user_details.state_id) {
            this.setState(
              {
                district_id: response.data.data.user_details.district_id,
              },
              () => {
                if (this.props.type == "place") {
                  this.get_district_list();
                }
              }
            );
          }
          if (response.data.data.partner_details.pstate_id) {
            this.setState(
              {
                pstate_id: response.data.data.partner_details.pstate_id,
              },
              () => {
                if (this.props.type == "partner") {
                  this.get_pdistrict_list();
                }
              }
            );
          } else {
            // console.log("ok");
          }
          if (response.data.data.user_details.taluka_id) {
            this.setState(
              {
                taluka_id: response.data.data.user_details.taluka_id,
              },
              () => this.get_taluka_list()
            );
          }
          if (response.data.data.partner_details.age.includes(",")) {
            let textStr = response.data.data.partner_details.age.split(",");
            this.setState({ age_range: textStr });
          } else {
            this.setState({ age_range: [23, 27] });
          }
          // console.log(
          //   "response.data.data.partner_details.pdistrict_id",
          //   response.data.data.partner_details.pdistrict_id
          // );

          let pd = JSON.parse(response.data.data.partner_details.pdistrict_id);
          let d_list = pd;
          this.setState(
            {
              profile_images: imgarr,
              name: response.data.data.user_details.name
                ? response.data.data.user_details.name
                : "",
              email: response.data.data.user_details.email
                ? response.data.data.user_details.email
                : "",
              marital_status: response.data.data.user_details.marital_status
                ? response.data.data.user_details.marital_status
                : "",
              state_name: response.data.data.user_details.state_name
                ? response.data.data.user_details.state_name
                : "",
              district_name: response.data.data.user_details.district_name
                ? response.data.data.user_details.district_name
                : "",
              taluka_name: response.data.data.user_details.taluka_name
                ? response.data.data.user_details.taluka_name
                : "",

              state_id: response.data.data.user_details.state_id
                ? response.data.data.user_details.state_id
                : "",
              district_id: response.data.data.user_details.district_id
                ? response.data.data.user_details.district_id
                : "",
              taluka_id: response.data.data.user_details.taluka_id
                ? response.data.data.user_details.taluka_id
                : "",
              address: response.data.data.user_details.address
                ? response.data.data.user_details.address
                : "",

              dob: response.data.data.user_details.dob
                ? dayjs(response.data.data.user_details.dob, "YYYY-MM-DD")
                : null,
              mother_toungue: response.data.data.user_details.mother_toungue
                ? response.data.data.user_details.mother_toungue
                : "",
              profile_created_by: response.data.data.user_details
                .profile_created_by
                ? response.data.data.user_details.profile_created_by
                : "",
              height: response.data.data.user_details.height
                ? response.data.data.user_details.height
                : "",
              highest_qualification: response.data.data.user_details
                .highest_qualification
                ? response.data.data.user_details.highest_qualification
                : "",
              employee_in: response.data.data.user_details.employee_in
                ? response.data.data.user_details.employee_in
                : "",

              religious_status: response.data.data.user_details.religious_status
                ? response.data.data.user_details.religious_status
                : "",

              about_self: response.data.data.user_details.about_self
                ? response.data.data.user_details.about_self
                : "",
              email: response.data.data.user_details.email
                ? response.data.data.user_details.email
                : "",
              phone: response.data.data.user_details.phone
                ? response.data.data.user_details.phone
                : "",
              disability: response.data.data.user_details.disability
                ? response.data.data.user_details.disability
                : "",
              mathhab_followed: response.data.data.user_details.mathhab_followed
                ? response.data.data.user_details.mathhab_followed
                : "",
              own_age: response.data.data.user_details.own_age
                ? response.data.data.user_details.own_age
                : "",
              born_or_reverted: response.data.data.user_details.born_or_reverted
                ? response.data.data.user_details.born_or_reverted
                : "",
              // age_range: response.data.data.partner_details.age
              //   ? [response.data.data.partner_details.age]
              //   : [23, 27],

              pmarital_status: response.data.data.partner_details
                .pmarital_status
                ? response.data.data.partner_details.pmarital_status
                : "",
              planguages: response.data.data.partner_details.planguages
                ? response.data.data.partner_details.planguages
                : "",
              peducation: response.data.data.partner_details.peducation
                ? response.data.data.partner_details.peducation
                : "",
              pdisability: response.data.data.partner_details.pdisability
                ? response.data.data.partner_details.pdisability
                : "",
              partner_districts: response.data.data.partner_details.p_districts
                ? response.data.data.partner_details.p_districts
                : "",
              pheight: response.data.data.partner_details.pheight
                ? response.data.data.partner_details.pheight
                : "",
              // pstate_id: response.data.data.partner_details.pstate_id,
              pdistrict_id: response.data.data.partner_details.pdistrict_id
                ? d_list
                : [],
              pmathhab_followed: response.data.data.partner_details
                .pmathhab_followed
                ? response.data.data.partner_details.pmathhab_followed
                : "",
            },
            () => this.setState({ loading: false })
          );
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((error) => {
        // console.error("Warning:", error);
        this.setState({ loading: false });
      });
  };
  validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };
  update_details = (param) => {
    if (param == "1") {
      if (!this.state.about_self) {
        message.warning("Please enter about self description.");
        return;
      }
      let params = {
        id: localStorage.getItem("r_id"),
        about_self: this.state.about_self,
        step: "1",
      };
      this.setState({ addLoader: true });
      axios
        .post(URLs.update_profile_details, params)
        .then((response) => {
          if (response.data.status == "1") {
            message.success(response.data.message);
            this.setState(
              {
                loading: false,
              },
              () => this.get_profile_details()
            );
            return;
          } else {
            this.setState({
              loading: false,
            });
            message.warning(response.data.message);
            return;
          }
        })
        .catch((error) => {
          this.setState({ loading: false });
          // console.error("Warning:", error);
          message.warning("Please try again.");
        });
    } else if (param == "2") {
      if (!this.state.name) {
        message.warning("Name is required.");
        return;
      } else if (!this.state.phone) {
        message.warning("Phone no is required.");
        return;
      } else if (!this.state.mother_toungue) {
        message.warning("Mother toungue is required.");
        return;
      } else if (!this.state.profile_created_by) {
        message.warning("Profile created by is required.");
        return;
      }
      if (this.state.email) {
        if (this.validateEmail(this.state.email) == false) {
          message.warning("Please enter valid format of email address");
          return;
        }
      }
      let params = {
        id: localStorage.getItem("r_id"),
        email: this.state.email ? this.state.email : "",
        phone: this.state.phone,
        name: this.state.name,
        mother_toungue: this.state.mother_toungue,
        profile_created_by: this.state.profile_created_by,
        step: param,
      };
      this.setState({ addLoader: true });
      axios
        .post(URLs.update_profile_details, params)
        .then((response) => {
          if (response.data.status == "1") {
            message.success(response.data.message);
            this.setState(
              {
                loading: false,
              },
              () => this.get_profile_details()
            );
            return;
          } else {
            this.setState({
              loading: false,
            });
            message.warning(response.data.message);
            return;
          }
        })
        .catch((error) => {
          this.setState({ loading: false });
          // console.error("Warning:", error);
          message.warning("Please try again.");
        });
    } else if (param == "3") {
      if (!this.state.dob) {
        message.warning("Date of birth is required.");
        return;
      }
      if (this.state.own_age < 18) {
        message.warning(
          "Age should be at least 18 Years. Please select valid Date of birth"
        );
        return;
      }
      if (!this.state.marital_status) {
        message.warning("Marital Status is required.");
        return;
      }
      let dob = dayjs(this.state.dob).format("YYYY-MM-DD");
      let params = {
        id: localStorage.getItem("r_id"),
        marital_status: this.state.marital_status
          ? this.state.marital_status
          : "",
        dob: this.state.dob ? dob : "",
        own_age: this.state.own_age,
        born_or_reverted: this.state.born_or_reverted
          ? this.state.born_or_reverted
          : "",
        mathhab_followed: this.state.mathhab_followed
          ? this.state.mathhab_followed
          : "",
        step: param,
      };
      this.setState({ addLoader: true });
      axios
        .post(URLs.update_profile_details, params)
        .then((response) => {
          if (response.data.status == "1") {
            message.success(response.data.message);
            this.setState(
              {
                loading: false,
              },
              () => this.get_profile_details()
            );
            return;
          } else {
            this.setState({
              loading: false,
            });
            message.warning(response.data.message);
            return;
          }
        })
        .catch((error) => {
          this.setState({ loading: false });
          // console.error("Warning:", error);
          message.warning("Please try again.");
        });
    } else if (param == "4") {
      let params = {
        id: localStorage.getItem("r_id"),
        height: this.state.height ? this.state.height : "",
        disability: this.state.disability ? this.state.disability : "",
        step: param,
      };
      this.setState({ addLoader: true });
      axios
        .post(URLs.update_profile_details, params)
        .then((response) => {
          if (response.data.status == "1") {
            message.success(response.data.message);
            this.setState(
              {
                loading: false,
              },
              () => this.get_profile_details()
            );
            return;
          } else {
            this.setState({
              loading: false,
            });
            message.warning(response.data.message);
            return;
          }
        })
        .catch((error) => {
          this.setState({ loading: false });
          // console.error("Warning:", error);
          message.warning("Please try again.");
        });
    } else if (param == "5") {
      let params = {
        id: localStorage.getItem("r_id"),
        state_id: this.state.state_id ? this.state.state_id : "",
        district_id: this.state.district_id ? this.state.district_id : "",
        taluka_id: this.state.taluka_id ? this.state.taluka_id : "",
        address: this.state.address ? this.state.address : "",
        step: param,
      };
      // console.log("params", params);
      this.setState({ addLoader: true });
      axios
        .post(URLs.update_profile_details, params)
        .then((response) => {
          if (response.data.status == "1") {
            message.success(response.data.message);
            this.setState(
              {
                loading: false,
              },
              () => this.get_profile_details()
            );
            return;
          } else {
            this.setState({
              loading: false,
            });
            message.warning(response.data.message);
            return;
          }
        })
        .catch((error) => {
          this.setState({ loading: false });
          // console.error("Warning:", error);
          message.warning("Please try again.");
        });
    } else if (param == "6") {
      let params = {
        id: localStorage.getItem("r_id"),
        highest_qualification: this.state.highest_qualification
          ? this.state.highest_qualification
          : "",
        employee_in: this.state.employee_in ? this.state.employee_in : "",
        step: param,
      };
      this.setState({ addLoader: true });
      axios
        .post(URLs.update_profile_details, params)
        .then((response) => {
          if (response.data.status == "1") {
            message.success(response.data.message);
            this.setState(
              {
                loading: false,
              },
              () => this.get_profile_details()
            );
            return;
          } else {
            this.setState({
              loading: false,
            });
            message.warning(response.data.message);
            return;
          }
        })
        .catch((error) => {
          this.setState({ loading: false });
          // console.error("Warning:", error);
          message.warning("Please try again.");
        });
    } else if (param == "7") {
      let formdata = new FormData();
      formdata.append("age", this.state.age_range);
      formdata.append(
        "pmarital_status",
        this.state.pmarital_status ? this.state.pmarital_status : ""
      );
      formdata.append(
        "pmathhab_followed",
        this.state.pmathhab_followed ? this.state.pmathhab_followed : ""
      );
      formdata.append("pheight", this.state.pheight ? this.state.pheight : "");
      formdata.append(
        "planguages",
        this.state.planguages ? this.state.planguages : ""
      );
      formdata.append(
        "peducation",
        this.state.peducation ? this.state.peducation : ""
      );
      formdata.append(
        "pdisability",
        this.state.pdisability ? this.state.pdisability : ""
      );
      formdata.append(
        "pstate_id",
        this.state.pstate_id ? this.state.pstate_id : ""
      );
      formdata.append("id", localStorage.getItem("r_id"));
      formdata.append("step", param);
      formdata.append("pdistrict_id", JSON.stringify(this.state.pdistrict_id));

      this.setState({ addLoader: true });
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      axios
        .post(URLs.update_profile_details, formdata, config)
        .then((response) => {
          if (response.data.status == "1") {
            message.success(response.data.message);
            this.setState(
              {
                loading: false,
              },
              () => this.get_profile_details()
            );
            return;
          } else {
            this.setState({
              loading: false,
            });
            message.warning(response.data.message);
            return;
          }
        })
        .catch((error) => {
          this.setState({ loading: false });
          // console.error("Warning:", error);
          message.warning("Please try again.");
        });
    }
  };
  calculateAge = (birthDate) => {
    var currentDate = new Date();
    var birthDate = new Date(birthDate);

    var age = currentDate.getFullYear() - birthDate.getFullYear();

    // Adjust age if the current date is before the birthdate in the same year
    if (
      currentDate.getMonth() < birthDate.getMonth() ||
      (currentDate.getMonth() === birthDate.getMonth() &&
        currentDate.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    this.setState({
      own_age: age,
    });
    // console.log("age", age);
    return age;
  };
  onChange = (checked) => {
    this.setState({
      age_range: checked,
    });
  };
  render() {
    return (
      <div>
        <Spin spinning={this.state.loading}>
          {this.props.type == "about-myself" && (
            <div className="about-self">
              <div className="details-header d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <box-icon type="solid" name="user" size={"19px"}></box-icon>
                  &nbsp;&nbsp;
                  <h5 className="mb-0 pb-0">About Myself</h5>
                </div>
              </div>
              <p>
                <textarea
                  id="txtid"
                  name="txtname"
                  rows="3"
                  cols="50"
                  value={this.state.about_self}
                  onChange={(e) => {
                    this.setState({
                      about_self: e.target.value,
                    });
                  }}
                  maxlength="200"
                  className="form-control"
                  style={{ border: "1px solid #bdb5b5" }}
                ></textarea>
                <Button
                  type="primary"
                  className="mt-2"
                  onClick={() => this.update_details(1)}
                >
                  Update
                </Button>
              </p>
            </div>
          )}
          {this.props.type == "basic-details" && (
            <div className="about-self">
              <div className="details-header d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <box-icon type="solid" name="user" size={"19px"}></box-icon>
                  &nbsp;&nbsp;
                  <h5 className="mb-0 pb-0">Basic Details</h5>
                </div>
              </div>
              <div className="all-list">
                <div className="row">
                  <div className="col-6">
                    <p className="param">
                      Name <span className="text-danger">*</span>
                    </p>
                  </div>
                  <div className="col-12">
                    <p className="value">
                      <div className="form-group">
                        <input
                          type="text"
                          name="email"
                          size="30"
                          maxlength="50"
                          placeholder="Full Name"
                          value={this.state.name}
                          onChange={(e) => {
                            this.setState({
                              name: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <p className="param">Email</p>
                  </div>
                  <div className="col-12">
                    <p className="value">
                      <div className="form-group">
                        <input
                          type="text"
                          id="email"
                          name="email"
                          size="30"
                          maxlength="50"
                          placeholder="Email Address"
                          value={this.state.email}
                          onChange={(e) => {
                            this.setState({
                              email: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <p className="param">Phone No</p>
                  </div>
                  <div className="col-12">
                    <p className="value">
                      <div className="form-group">
                        <input
                          type="number"
                          id="phone"
                          name="phone"
                          maxLength={10}
                          pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                          value={this.state.phone}
                          onChange={(e) => {
                            this.setState({
                              phone: e.target.value,
                            });
                          }}
                        />{" "}
                      </div>
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <p className="param">Mother Tounge</p>
                  </div>
                  <div className="col-12">
                    <p className="value">
                      <div className="form-group">
                        <select
                          value={this.state.mother_toungue}
                          onChange={(e) => {
                            this.setState({
                              mother_toungue: e.target.value,
                            });
                          }}
                          data-placeholder="Mother Tounge"
                        >
                          <option value="">Select</option>
                          {this.state.mother_toungue_list &&
                            this.state.mother_toungue_list.map(
                              (item, index) => {
                                return (
                                  <option value={item} key={index}>
                                    {item}
                                  </option>
                                );
                              }
                            )}
                        </select>
                      </div>
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <p className="param">Profile Created By</p>
                  </div>
                  <div className="col-12">
                    <p className="value">
                      <div className="form-group">
                        <select
                          value={this.state.profile_created_by}
                          onChange={(e) => {
                            this.setState({
                              profile_created_by: e.target.value,
                            });
                          }}
                          name="name"
                        >
                          <option value="">Select</option>
                          {this.state.created_by_list &&
                            this.state.created_by_list.map((item, index) => {
                              return (
                                <option value={item} key={index}>
                                  {item}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                      <Button
                        type="primary"
                        className="mt-2"
                        onClick={() => this.update_details(2)}
                      >
                        Update
                      </Button>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* start Personal details   */}
          {this.props.type == "personal-details" && (
            <div className="about-self">
              <div className="details-header d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <box-icon type="solid" name="user" size={"19px"}></box-icon>
                  &nbsp;&nbsp;
                  <h5 className="mb-0 pb-0">Personal Details</h5>
                </div>
              </div>
              <div className="all-list">
                <div className="row">
                  <div className="col-6">
                    <p className="param">Marital Status</p>
                  </div>
                  <div className="col-12">
                    <p className="value">
                      <div className="form-group">
                        <select
                          name="marital_status"
                          value={this.state.marital_status}
                          onChange={(e) => {
                            this.setState({
                              marital_status: e.target.value,
                            });
                          }}
                          id="marital_status"
                        >
                          <option value="">Select</option>
                          <option value="Single">Single</option>
                          <option value="Married">Married</option>
                          <option value="Widowed">Widowed</option>
                          <option value="Separated">Separated</option>
                          <option value="Divorced">Divorced</option>
                        </select>
                      </div>
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p className="param">Date of Birth</p>
                      <span>Age: {this.state.own_age} Years</span>
                    </div>
                  </div>
                  <div className="col-12">
                    <p className="value">
                      <div className="form-group">
                        <DatePicker
                          value={this.state.dob ? this.state.dob : null}
                          onChange={(date, dateString) => {
                            this.setState(
                              {
                                dob: date,
                              },
                              () => this.calculateAge(date)
                            );
                          }}
                          style={{
                            width: "100%",
                          }}
                          className="date-picker-input-box update-dpic"
                        />
                        {this.state.own_age < 18 &&
                          this.state.own_age != "0" && (
                            <span className="text-danger">
                              Invalid Date of Birth
                            </span>
                          )}
                      </div>
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <p className="param">Math'hab Followed</p>
                  </div>
                  <div className="col-12">
                    <p className="value">
                      <div className="form-group">
                        <select
                          value={this.state.mathhab_followed}
                          onChange={(e) => {
                            this.setState({
                              mathhab_followed: e.target.value,
                            });
                          }}
                          name="math'hab followed"
                          id="math'hab followed"
                        >
                          <option value="">Select</option>
                          <option value="Hanafi">Hanafi</option>
                          <option value="Hanbali">Hanbali</option>
                          <option value="Maliki">Maliki</option>
                          <option value="Shafi">Shafi</option>
                          <option value="Shia">Shia</option>
                          <option value="Just Muslim">Just Muslim</option>
                          <option value="Other">Other</option>
                        </select>
                      </div>
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <p className="param">Born or reverted</p>
                  </div>
                  <div className="col-12">
                    <p className="value">
                      <div className="form-group">
                        <div className="gender-option d-flex justify-content-between">
                          <label for="male">Born</label>
                          <input
                            type="radio"
                            style={{ width: 40 }}
                            onClick={() => {
                              this.setState({
                                born_or_reverted: "Born",
                              });
                            }}
                            checked={
                              this.state.born_or_reverted == "Born"
                                ? true
                                : false
                            }
                            id="male"
                            name="born_or_reverted"
                            value="Born"
                          />
                        </div>
                        <div className="gender-option d-flex justify-content-between">
                          <label for="female" className="gender-option">
                            Reverted
                          </label>
                          <input
                            style={{ width: 40 }}
                            type="radio"
                            onClick={() => {
                              this.setState({
                                born_or_reverted: "Reverted",
                              });
                            }}
                            checked={
                              this.state.born_or_reverted == "Reverted"
                                ? true
                                : false
                            }
                            id="female"
                            name="born_or_reverted"
                            value="Reverted"
                          />
                        </div>
                      </div>
                    </p>
                  </div>
                </div>
                <Button
                  type="primary"
                  className="mt-2"
                  onClick={() => this.update_details(3)}
                >
                  Update
                </Button>
              </div>
            </div>
          )}
          {/* end Personal details */}

          {/* Start appearance  */}
          {this.props.type == "appearance" && (
            <div className="about-self">
              <div className="details-header d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <box-icon type="solid" name="user" size={"19px"}></box-icon>
                  &nbsp;&nbsp;
                  <h5 className="mb-0 pb-0">Your Appearance </h5>
                </div>
              </div>
              <div className="all-list">
                <div className="row">
                  <div className="col-6">
                    <p className="param">Height</p>
                  </div>
                  <div className="col-12">
                    <p className="value">
                      <div className="form-group">
                        <select
                          value={this.state.height}
                          onChange={(e) => {
                            this.setState({
                              height: e.target.value,
                            });
                          }}
                          name="name"
                          id="name"
                        >
                          <option value="Select">Select</option>
                          <option value="4ft 6in/137cms ">
                            4ft 6in/137cms
                          </option>
                          <option value="4ft 7in/139cms">4ft 7in/139cms</option>
                          <option value="4ft 8in/142cms">4ft 8in/142cms</option>
                          <option value="4ft 9in/144cms">4ft 9in/144cms</option>
                          <option value="4ft 10in/147cms">
                            4ft 10in/147cms
                          </option>
                          <option value="4ft 11in/149cms">
                            4ft 11in/149cms
                          </option>
                          <option value="5ft/152cms">5ft/152cms</option>
                          <option value="5ft 1in/154cms">5ft 1in/152cms</option>
                          <option value="5ft 2in/157cms">5ft 2in/157cms</option>
                          <option value="5ft 3in/160cms">5ft 3in/160cms</option>
                          <option value="5ft 4in/162cms">5ft 4in/162cms</option>
                          <option value="5ft 5in/165cms">5ft 5in/165cms</option>
                          <option value="5ft 6in/167cms">5ft 6in/167cms</option>
                          <option value="5ft 7in/170cms">5ft 7in/170cms</option>
                          <option value="5ft 8in/172cms">5ft 8in/172cms</option>
                          <option value="5ft 9in/175cms">5ft 9in/175cms</option>
                          <option value="5ft 10in/177cms">
                            5ft 10in/177cms
                          </option>
                          <option value="5ft 11in/180cms">
                            5ft 11in/180cms
                          </option>
                          <option value="6ft/182cms">6ft/182cms</option>
                          <option value="6ft 1in/185cms">6ft 1in/185cms</option>
                          <option value="6ft 2in/187cms">6ft 2in/187cms</option>
                          <option value="6ft 3in/190cms">6ft 3in/190cms</option>
                          <option value="6ft 4in/193cms">6ft 4in/193cms</option>
                          <option value="6ft 5in/195cms">6ft 5in/195cms</option>
                          <option value="6ft 6in/198cms">6ft 6in/198cms</option>
                          <option value="6ft 7in/200cms">6ft 7in/200cms</option>
                          <option value="6ft 8in/203cms">6ft 8in/203cms</option>
                          <option value="6ft 9in/205cms">6ft/205cms</option>
                          <option value="6ft 10in/208cms">6ft/208cms</option>
                          <option value="6ft 11in/210cms">6ft/210cms</option>
                          <option value="7in/213cms">7ft/213cms</option>
                        </select>
                      </div>
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <p className="param">Disability</p>
                  </div>
                  <div className="col-12">
                    <p className="value">
                      <div className="form-group">
                        <select
                          name="name"
                          value={this.state.disability}
                          onChange={(e) => {
                            this.setState({
                              disability: e.target.value,
                            });
                          }}
                          id="name"
                        >
                          <option value="none">None</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                      </div>
                    </p>
                  </div>
                </div>
                <Button
                  type="primary"
                  className="mt-2"
                  onClick={() => this.update_details(4)}
                >
                  Update
                </Button>
              </div>
            </div>
          )}
          {/* end appearance  */}

          {/* Start place  */}
          {this.props.type == "place" && (
            <div className="about-self">
              <div className="details-header d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <box-icon type="solid" name="user" size={"19px"}></box-icon>
                  &nbsp;&nbsp;
                  <h5 className="mb-0 pb-0">Place you are from </h5>
                </div>
              </div>
              <div className="all-list">
                <div className="row">
                  <div className="col-6">
                    <p className="param">State Name</p>
                  </div>
                  <div className="col-12">
                    <p className="value">
                      <div className="form-group">
                        <select
                          value={this.state.state_id}
                          onChange={(e) => {
                            this.setState(
                              {
                                state_id: e.target.value,
                              },
                              () => this.get_district_list()
                            );
                          }}
                          name="name"
                          id="name"
                        >
                          <option value="">Select</option>
                          {this.state.state_list &&
                            this.state.state_list.map((item, index) => {
                              return (
                                <option value={item.state_id} key={index}>
                                  {item.state_name}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <p className="param">District Name</p>
                  </div>
                  <div className="col-12">
                    <p className="value">
                      <div className="form-group">
                        <select
                          name="name"
                          value={this.state.district_id}
                          onChange={(e) => {
                            this.setState(
                              {
                                district_id: e.target.value,
                              },
                              () => this.get_taluka_list()
                            );
                          }}
                          id="name"
                        >
                          <option value="">Select</option>
                          {this.state.district_list &&
                            this.state.district_list.map((item, index) => {
                              return (
                                <option value={item.district_id} key={index}>
                                  {item.name}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </p>
                  </div>
                </div>
                {/* <div className="row">
                <div className="col-6">
                  <p className="param">Taluka Name</p>
                </div>
                <div className="col-12">
                  <p className="value">
                    <div className="form-group">
                      <select
                        name="name"
                        value={this.state.taluka_id}
                        onChange={(e) => {
                          this.setState({
                            taluka_id: e.target.value,
                          });
                        }}
                        id="name"
                      >
                        <option value="">Select</option>
                        {this.state.taluka_list &&
                          this.state.taluka_list.map((item, index) => {
                            return (
                              <option value={item.taluka_id} key={index}>
                                {item.name}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </p>
                </div>
              </div> */}
                <div className="row">
                  <div className="col-6">
                    <p className="param">Address</p>
                  </div>
                  <div className="col-12">
                    <p className="value">
                      <div className="form-group">
                        <textarea
                          id="txtid"
                          name="txtname"
                          rows="3"
                          cols="50"
                          value={this.state.address}
                          onChange={(e) => {
                            this.setState({
                              address: e.target.value,
                            });
                          }}
                          maxlength="200"
                          className="form-control"
                          style={{ border: "1px solid #bdb5b5" }}
                        ></textarea>
                      </div>
                    </p>
                  </div>
                </div>
                <Button
                  type="primary"
                  className="mt-2"
                  onClick={() => this.update_details(5)}
                >
                  Update
                </Button>
              </div>
            </div>
          )}
          {/* end place  */}

          {/* Start Qualification  */}
          {this.props.type == "qualification" && (
            <div className="about-self">
              <div className="details-header d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <box-icon type="solid" name="user" size={"19px"}></box-icon>
                  &nbsp;&nbsp;
                  <h5 className="mb-0 pb-0">About Qualification</h5>
                </div>
              </div>
              <div className="all-list">
                <div className="row">
                  <div className="col-12">
                    <p className="param">Highest Qualification</p>
                  </div>
                  <div className="col-12">
                    <p className="value">
                      <div className="form-group">
                        <input
                          type="text"
                          name="email"
                          size="30"
                          maxlength="50"
                          placeholder="Highest Qualification"
                          value={this.state.highest_qualification}
                          onChange={(e) => {
                            this.setState({
                              highest_qualification: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <p className="param">Employee In</p>
                  </div>
                  <div className="col-12">
                    <p className="value">
                      <div className="form-group">
                        <input
                          type="text"
                          name="email"
                          size="30"
                          maxlength="50"
                          placeholder="Employee In"
                          value={this.state.employee_in}
                          onChange={(e) => {
                            this.setState({
                              employee_in: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </p>
                  </div>
                </div>
              </div>
              <Button
                type="primary"
                className="mt-2"
                onClick={() => this.update_details(6)}
              >
                Update
              </Button>
            </div>
          )}
          {/* end Qualification */}

          {/* Start Partner preference  */}
          {this.props.type == "partner" && (
            <div className="about-self">
              <div className="details-header d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <box-icon type="solid" name="user" size={"19px"}></box-icon>
                  &nbsp;&nbsp;
                  <h5 className="mb-0 pb-0">Partner Preferences </h5>
                </div>
              </div>
              <div className="all-list">
                <div className="row">
                  <div className="col-6">
                    <p className="param">Age Range</p>
                  </div>
                  <div className="col-12">
                    <p className="value">
                      <div className="form-group">
                        <Slider
                          min={18}
                          max={55}
                          range
                          onChange={this.onChange}
                          value={this.state.age_range}
                        />
                        <p className="text-center">
                          {this.state.age_range[0]} - {this.state.age_range[1]}
                        </p>
                      </div>
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <p className="param">Martial Status</p>
                  </div>
                  <div className="col-12">
                    <p className="value">
                      <div className="form-group">
                        <select
                          value={this.state.pmarital_status}
                          onChange={(e) =>
                            this.setState({ pmarital_status: e.target.value })
                          }
                          name="status"
                          id="status"
                        >
                          <option value="select">select</option>
                          <option value="never married">Never Married</option>
                          <option value="widowed">Widowed</option>
                          <option value="divorced">Divorced</option>
                          <option value="awt divorced">
                            Awaiting divorced
                          </option>
                        </select>
                      </div>
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <p className="param">Mazhab</p>
                  </div>
                  <div className="col-12">
                    <p className="value">
                      <div className="form-group">
                        <select
                          name="math"
                          id="math"
                          value={this.state.pmathhab_followed}
                          onChange={(e) =>
                            this.setState({ pmathhab_followed: e.target.value })
                          }
                        >
                          <option value="">Select</option>
                          <option value="Hanafi">Hanafi</option>
                          <option value="Hanbali">Hanbali</option>
                          <option value="Maliki">Maliki</option>
                          <option value="Shafi">Shafi</option>
                          <option value="Shia">Shia</option>
                          <option value="Just Muslim">Just Muslim</option>
                          <option value="Other">Other</option>
                        </select>
                      </div>
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <p className="param">Height</p>
                  </div>
                  <div className="col-12">
                    <p className="value">
                      <div className="form-group">
                        <select
                          value={this.state.pheight}
                          onChange={(e) => {
                            this.setState({
                              pheight: e.target.value,
                            });
                          }}
                          name="name"
                          id="name"
                        >
                          <option value="Select">Select</option>
                          <option value="4ft 6in/137cms">4ft 6in/137cms</option>
                          <option value="4ft 7in/139cms">4ft 7in/139cms</option>
                          <option value="4ft 8in/142cms">4ft 8in/142cms</option>
                          <option value="4ft 9in/144cms">4ft 9in/144cms</option>
                          <option value="4ft 10in/147cms">
                            4ft 10in/147cms
                          </option>
                          <option value="4ft 11in/149cms">
                            4ft 11in/149cms
                          </option>
                          <option value="5ft/152cms">5ft/152cms</option>
                          <option value="5ft 1in/154cms">5ft 1in/152cms</option>
                          <option value="5ft 2in/157cms">5ft 2in/157cms</option>
                          <option value="5ft 3in/160cms">5ft 3in/160cms</option>
                          <option value="5ft 4in/162cms">5ft 4in/162cms</option>
                          <option value="5ft 5in/165cms">5ft 5in/165cms</option>
                          <option value="5ft 6in/167cms">5ft 6in/167cms</option>
                          <option value="5ft 7in/170cms">5ft 7in/170cms</option>
                          <option value="5ft 8in/172cms">5ft 8in/172cms</option>
                          <option value="5ft 9in/175cms">5ft 9in/175cms</option>
                          <option value="5ft 10in/177cms">
                            5ft 10in/177cms
                          </option>
                          <option value="5ft 11in/180cms">
                            5ft 11in/180cms
                          </option>
                          <option value="6ft/182cms">6ft/182cms</option>
                          <option value="6ft 1in/185cms">6ft 1in/185cms</option>
                          <option value="6ft 2in/187cms">6ft 2in/187cms</option>
                          <option value="6ft 3in/190cms">6ft 3in/190cms</option>
                          <option value="6ft 4in/193cms">6ft 4in/193cms</option>
                          <option value="6ft 5in/195cms">6ft 5in/195cms</option>
                          <option value="6ft 6in/198cms">6ft 6in/198cms</option>
                          <option value="6ft 7in/200cms">6ft 7in/200cms</option>
                          <option value="6ft 8in/203cms">6ft 8in/203cms</option>
                          <option value="6ft 9in/205cms">6ft/205cms</option>
                          <option value="6ft 10in/208cms">6ft/208cms</option>
                          <option value="6ft 11in/210cms">6ft/210cms</option>
                          <option value="7in/213cms">7ft/213cms</option>
                        </select>
                      </div>
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <p className="param">Language</p>
                  </div>
                  <div className="col-12">
                    <p className="value">
                      <div className="form-group">
                        <select
                          data-placeholder="language"
                          value={this.state.planguages}
                          onChange={(e) =>
                            this.setState({ planguages: e.target.value })
                          }
                        >
                          <option value="">Select</option>
                          {this.state.languages &&
                            this.state.languages.map((item, index) => {
                              return (
                                <option value={item} key={index}>
                                  {item}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <p className="param">Education</p>
                  </div>
                  <div className="col-12">
                    <p className="value">
                      <div className="form-group">
                        <input
                          type="text"
                          name="email"
                          size="30"
                          maxlength="50"
                          placeholder="Education"
                          value={this.state.peducation}
                          onChange={(e) => {
                            this.setState({
                              peducation: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <p className="param">Disability</p>
                  </div>
                  <div className="col-12">
                    <p className="value">
                      <div className="form-group">
                        <select
                          name="name"
                          id="name"
                          value={this.state.pdisability}
                          onChange={(e) =>
                            this.setState({ pdisability: e.target.value })
                          }
                        >
                          <option value="">Select</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                      </div>
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <p className="param">State</p>
                  </div>
                  <div className="col-12">
                    <p className="value">
                      <div className="form-group">
                        <select
                          value={this.state.pstate_id}
                          onChange={(e) => {
                            this.setState(
                              {
                                pstate_id: e.target.value,
                                pdistrict_id: [],
                              },
                              () => this.get_pdistrict_list()
                            );
                          }}
                          style={{ width: "100%" }}
                          name="name"
                          id="name"
                        >
                          <option value="">Select</option>
                          {this.state.state_list &&
                            this.state.state_list.map((item, index) => {
                              return (
                                <option value={item.state_id} key={index}>
                                  {item.state_name}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <p className="param">Districts</p>
                  </div>
                  <div className="col-12">
                    <p className="value">
                      <div className="form-group">
                        <Select
                          value={this.state.pdistrict_id}
                          onChange={(value) => {
                            this.setState({
                              pdistrict_id: value,
                            });
                          }}
                          style={{ width: "100%" }}
                          mode="multiple"
                          allowClear
                          name="name"
                          id="name"
                          placeholder="Select District"
                        >
                          {this.state.parner_district_list &&
                            this.state.parner_district_list.map(
                              (item, index) => {
                                return (
                                  <option value={item.district_id} key={index}>
                                    {item.name}
                                  </option>
                                );
                              }
                            )}
                        </Select>
                      </div>
                    </p>
                  </div>
                </div>
              </div>
              <Button
                type="primary"
                className="mt-2"
                onClick={() => this.update_details(7)}
              >
                Update
              </Button>
            </div>
          )}
          {/* End Partner preference  */}
        </Spin>
      </div>
    );
  }
}
