import React, { Component } from "react";
import axios from "axios";
import URLs from "../../utils/apis";
import { message, Spin } from "antd";

export default class Registration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nationality: "",
      residing_country: "",
      native_city: "",
      loading: false,
      state_id: "",
      district_id: "",
      taluka_id: "",
      address: "",
      state_list: [],
      district_list: [],
      taluka_list: [],
      got_user_id: false,
    };
  }
  componentDidMount() {
    this.get_state_list();
    if (this.state.got_user_id == false) {
      if (localStorage.getItem("registered_user_id")) {
        this.setState(
          {
            got_user_id: true,
          },
          () => this.get_user_details()
        );
      }
    }
  }
  componentDidCatch() {
    console.log("personal updated this.props.user_id", this.props.user_id);
    if (this.state.got_user_id == false) {
      if (localStorage.getItem("registered_user_id")) {
        this.setState(
          {
            got_user_id: true,
          },
          () => this.get_user_details()
        );
      }
    }
  }
  get_user_details = () => {
    let params = {
      user_id: localStorage.getItem("registered_user_id"),
    };
    axios
      .post(URLs.get_register_details, params)
      .then((response) => {
        if (response.data.status == "1") {
          if (response.data.data.length > 0) {
            console.log("response.data.data", response.data.data);
            this.setState({
              state_id: response.data.data[0].state_id,
              district_id: response.data.data[0].district_id,
              taluka_id: response.data.data[0].taluka_id,
              address: response.data.data[0].address,
            });
            if (response.data.data[0].district_id) {
              this.get_district_list();
            }
            if (response.data.data[0].taluka_id) {
              this.get_taluka_list();
            }
          }
          return;
        } else {
        }
      })
      .catch((error) => {});
  };
  get_state_list = () => {
    axios
      .get(URLs.get_state_list)
      .then((response) => {
        if (response.data.status == "1") {
          this.setState({
            state_list: response.data.data,
          });
          return;
        } else {
          return;
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
        message.warning("Please try again.");
      });
  };
  get_district_list = () => {
    let params = {
      state_id: this.state.state_id,
    };
    axios
      .post(URLs.get_district_list, params)
      .then((response) => {
        if (response.data.status == "1") {
          this.setState({
            district_list: response.data.data,
          });
          return;
        } else {
          return;
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
        message.warning("Please try again.");
      });
  };
  get_taluka_list = () => {
    let params = {
      d_id: this.state.district_id,
    };
    axios
      .post(URLs.get_taluka_list, params)
      .then((response) => {
        if (response.data.status == "1") {
          this.setState({
            taluka_list: response.data.data,
          });
          return;
        } else {
          return;
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
        message.warning("Please try again.");
      });
  };
  save = () => {
    let params = {
      state_id: this.state.state_id ? this.state.state_id : "",
      district_id: this.state.district_id ? this.state.district_id : "",
      taluka_id: this.state.taluka_id ? this.state.taluka_id : "",
      address: this.state.address ? this.state.address : "",
      user_id: localStorage.getItem("registered_user_id"),
      step: "4",
    };
    this.setState({ loading: true });
    axios
      .post(URLs.register, params)
      .then((response) => {
        if (response.data.status == "1") {
          message.success(response.data.message);
          this.setState(
            {
              loading: false,
            },
            () => this.props.change(5)
          );
          return;
        } else {
          this.setState({
            loading: false,
          });
          message.warning(response.data.message);
          return;
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
        message.warning("Please try again.");
      });
  };
  render() {
    return (
      <div className="container px-0 registration-page">
        <Spin spinning={this.state.loading}>
          <div className="row">
            <div className="col-lg-12">
              <h2>
                <b>Place you are from</b>
              </h2>
              <h6>Profile with more information gets better response</h6>
              <div className="form-group">
                <label for="height">State</label>
                <select
                  value={this.state.state_id}
                  onChange={(e) => {
                    this.setState(
                      {
                        state_id: e.target.value,
                      },
                      () => this.get_district_list()
                    );
                  }}
                  name="name"
                  id="name"
                >
                  <option value="">Select</option>
                  {this.state.state_list &&
                    this.state.state_list.map((item, index) => {
                      return (
                        <option value={item.state_id} key={index}>
                          {item.state_name}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div className="form-group">
                <label for="country">District</label>
                <select
                  name="name"
                  value={this.state.district_id}
                  onChange={(e) => {
                    this.setState(
                      {
                        district_id: e.target.value,
                      },
                      () => this.get_taluka_list()
                    );
                  }}
                  id="name"
                >
                  <option value="">Select</option>
                  {this.state.district_list &&
                    this.state.district_list.map((item, index) => {
                      return (
                        <option value={item.district_id} key={index}>
                          {item.name}
                        </option>
                      );
                    })}
                </select>
              </div>
              {/* <div className="form-group">
                <label for="country">Taluka</label>
                <select
                  name="name"
                  value={this.state.taluka_id}
                  onChange={(e) => {
                    this.setState({
                      taluka_id: e.target.value,
                    });
                  }}
                  id="name"
                >
                  <option value="">Select</option>
                  {this.state.taluka_list &&
                    this.state.taluka_list.map((item, index) => {
                      return (
                        <option value={item.taluka_id} key={index}>
                          {item.name}
                        </option>
                      );
                    })}
                </select>
              </div> */}
              <div className="form-group">
                <label for="country">Address</label>
                <textarea
                  id="txtid"
                  name="txtname"
                  rows="3"
                  cols="50"
                  value={this.state.address}
                  onChange={(e) => {
                    this.setState({
                      address: e.target.value,
                    });
                  }}
                  maxlength="200"
                  className="form-control"
                  style={{ border: "1px solid #bdb5b5" }}
                ></textarea>
              </div>
              <button
                className="submit-button"
                type="button"
                onClick={() => this.save()}
              >
                Continue
              </button>
              <button
                className="submit-button"
                type="button"
                onClick={() => this.props.change(3)}
              >
                Back
              </button>
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}
