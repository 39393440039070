import React, { Component } from "react";
import "flickity/css/flickity.css";
import { Spin } from "antd";
import axios from "axios";
import URLs from "./utils/apis";
import BottomNavigation from "./components/BottomNavigation";
// import MatchingProfiles from "./components/homePageLists/MatchingProfiles";
// import Recommendation from "./components/homePageLists/Recommendation";
// import Viewed from "./components/homePageLists/Viewed";
// import PremiumMatches from "./components/homePageLists/PremiumMatches";

import Header from "./components/Header";

export default class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user_list: [],
      cuser_list: [],
      loading: false,
      profile_image: "",
      username: "",
      searchText: "",
      perpage: 12,
      count: 1,
      total_count: 0,
    };
  }
  componentDidMount() {
    this.get_data();
    this.get_profile_details();
  }
  get_profile_details = () => {
    let user_id = localStorage.getItem("r_id");
    let params = {
      id: user_id,
    };
    axios
      .post(URLs.get_profile_details, params)
      .then((response) => {
        if (response.data.status == "1") {
          if (response.data.data.images.length > 0) {
            let url = "";
            if (URLs.image_base_url) {
              url = URLs.image_base_url + response.data.data.images[0].image;
            }
            this.setState({
              profile_image: url,
            });
          }
          this.setState({
            username: response.data.data.user_details.name,
          });
        }
      })
      .catch((error) => {
        console.error("Warning:", error);
      });
  };
  get_data = () => {
    this.setState({ loading: true });
    let user_id = localStorage.getItem("r_id");
    let params = {
      id: user_id,
      perpage: Number(this.state.perpage) * Number(this.state.count),
    };
    axios
      .post(URLs.get_user_list, params)
      .then((response) => {
        console.log("response", response);
        if (response.data.status == "1") {
          this.setState({
            user_list: response.data.data,
            cuser_list: response.data.data,
            total_count: response.data.total_count,
            loading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
      });
  };
  search = () => {
    let searchtext = this.state.searchText;
    let list = this.state.cuser_list;
    let temparr = [];
    for (let item of list) {
      if (
        (item.name &&
          item.name.toLowerCase().includes(searchtext.toLowerCase())) ||
        (item.state_name &&
          item.state_name.toLowerCase().includes(searchtext.toLowerCase())) ||
        (item.district_name &&
          item.district_name
            .toLowerCase()
            .includes(searchtext.toLowerCase())) ||
        (item.marital_status &&
          item.marital_status.toLowerCase().includes(searchtext.toLowerCase()))
      ) {
        temparr = [...temparr, item];
      }
    }
    this.setState({
      user_list: temparr,
    });
  };
  show_more = () => {
    let count = Number(this.state.count) + 1;
    let perpage = Number(this.state.perpage) * count;
    console.log("count", count);
    // console.log("perpage", perpage);
    this.setState(
      {
        count: count,
        // perpage: perpage,
      },
      () => this.get_data()
    );
  };
  render() {
    return (
      <>
        <Header />
        <div className="dashboard-page main top-space-for-fixed-bar">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h2 className="hi_heading">Hi, {this.state.username}</h2>
                {/* <h2 className="user_id_number">(AS8604622)</h2> */}
                <input
                  className="searchInput"
                  type="search"
                  placeholder="Search here..."
                  onChange={(e) => {
                    this.setState({ searchText: e.target.value }, () =>
                      this.search()
                    );
                  }}
                />
                {/* no-image-available */}
                <div className="user_list_section">
                  <Spin spinning={this.state.loading}>
                    <div className="row">
                      {this.state.user_list.length > 0 &&
                        this.state.user_list.map((item, index) => {
                          let imageurl = "";
                          if (item.profile) {
                            imageurl = URLs.image_base_url + item.profile;
                          } else {
                            imageurl = "./assets/images/no-image-available.jpg";
                          }
                          return (
                            <div className="col-6">
                              <div
                                className="single-profile-block"
                                style={{
                                  background: item.profile
                                    ? `url(${imageurl})`
                                    : `url(${imageurl})`,
                                  backgroundSize: "cover",
                                }}
                              >
                                <a
                                  href={`/user-details?id=${item.id}`}
                                  style={{ textDecoration: "none" }}
                                >
                                  <div className="black-drop">
                                    <p
                                      className="name"
                                      style={{
                                        background: item.profile
                                          ? "#a09f9f87"
                                          : "#3e3e3e",
                                      }}
                                    >
                                      {item.name}
                                    </p>
                                    <div className="other-details">
                                      <p
                                        className="age"
                                        style={{
                                          background: item.profile
                                            ? "#a09f9f87"
                                            : "#3e3e3e",
                                        }}
                                      >
                                        {item.own_age}, {item.marital_status}
                                      </p>
                                      <p
                                        className="address"
                                        style={{
                                          background: item.profile
                                            ? "#a09f9f87"
                                            : "#3e3e3e",
                                        }}
                                      >
                                        {item.state_name}
                                        {item.state_name
                                          ? ", " + item.district_name
                                          : item.district_name}
                                      </p>
                                    </div>
                                  </div>
                                </a>
                              </div>
                            </div>
                          );
                        })}
                      {this.state.user_list.length == 0 && (
                        <div className="col-12">
                          <p className="text-center" style={{ fontSize: 20 }}>
                            Not available..
                          </p>
                        </div>
                      )}
                    </div>
                    {this.state.cuser_list.length != this.state.total_count && (
                      <button
                        type="button"
                        className="read_more_button"
                        onClick={() => this.show_more()}
                      >
                        Show More
                      </button>
                    )}
                  </Spin>

                  <div className="dashboard-footer">
                    <div className="row">
                      <div className="col-6 px-0">
                        <div className="inner">
                          <div className="icon">
                            <box-icon name="chat" color="#0d085c"></box-icon>
                          </div>
                          <div className="content">
                            <h6>Direct Communication</h6>
                            <p>
                              Communicate with confidence. Every profile is
                              genuine.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-6 px-0">
                        <div
                          className="inner"
                          style={{
                            paddingLeft: 13,
                          }}
                        >
                          <div className="icon">
                            <box-icon name="bell" color="#0d085c"></box-icon>
                          </div>
                          <div className="content">
                            <h6>Get Notified</h6>
                            <p>Get notified promptly with our egular alerts!</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-6 px-0">
                        <div className="inner">
                          <div className="icon">
                            <box-icon
                              name="image-add"
                              color="#0d085c"
                            ></box-icon>
                          </div>
                          <div className="content">
                            <h6>Add More Photos</h6>
                            <p>More pictures gives you more visbiliy.</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-6 px-0">
                        <div
                          className="inner"
                          style={{
                            paddingLeft: 13,
                          }}
                        >
                          <div className="icon">
                            <box-icon name="search" color="#0d085c"></box-icon>
                          </div>
                          <div className="content">
                            <h6>Find best muslim match</h6>
                            <p>
                              Find the right match from the city of your choice.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Start Matching Profiles  */}
                {/* <MatchingProfiles /> */}
                {/* End Matching Profiles  */}
                {/* Start Recommendation  */}
                {/* <Recommendation /> */}
                {/* End Recommendation  */}
                {/* Start Viewed, Not contacted  */}
                {/* <Viewed /> */}
                {/* End Viewed, Not contacted  */}
                {/* Start Premium Matches  */}
                {/* <PremiumMatches /> */}
                {/* End Premium Matches  */}
                <BottomNavigation active="home" />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
